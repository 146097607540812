import React from 'react'

import Icon from '../Icon'

import { useIntl } from '../../Contexts/IntlContext/IntlState'
import { OneColumnContentBox, OneColumnContentSection } from '../OneColumnContentBox'
import { H1, PrimaryText } from '../../typography'
import LinkBetweenText from '../LinkBetweenText/LinkBetweenText'

const TEXT_ALIGN_CENTER = { textAlign: 'center' }

const LinkExpired = React.memo(() => {
  const { toIntlString } = useIntl()
  return (
    <OneColumnContentBox>
      <OneColumnContentSection disableFormFocus>
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
          <div style={{ marginTop: '16px' }}>
            <Icon width={32} name="invite_link_logo" alt={toIntlString('error.error')} />
          </div>
          <div>
            <H1>{toIntlString('link_expired.title')}</H1>
          </div>
          <div style={{ marginTop: '16px', marginBottom: '32px' }}>
            <PrimaryText>
              <LinkBetweenText
                translationKey={'link_expired.expired_or_no_access'}
                placeHolderReplacementObject={{
                  email: {
                    textKey: 'error.contact_us',
                    link: 'mailTo:support@freshworks.com'
                  }
                }}
                customStyles={{ fontSize: '16px' }}
              />
            </PrimaryText>
          </div>
        </div>
      </OneColumnContentSection>
    </OneColumnContentBox>
  )
})

export default LinkExpired
