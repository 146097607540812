import { PrimaryText } from '../../typography'
import './TooltipText.css'
const TooltipText = ({
  tooltipText,
  children,
  style = {},
  forceDisable = false,
  alwaysShow = false
}) => {
  return (
    <>
      {!forceDisable ? (
        <div className={`tooltip-container${alwaysShow ? ' always-show' : ''}`}>
          {children}
          <span style={style} className="tooltip-text">
            <PrimaryText
              style={{
                color: 'white',
                fontSize: '12px',
                whiteSpace: 'pre-wrap',
                maxWidth: '600px',
                padding: '0px 10px'
              }}>
              {tooltipText}
            </PrimaryText>
          </span>
        </div>
      ) : (
        children
      )}
    </>
  )
}

export default TooltipText
