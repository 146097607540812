import React from 'react'
import PropTypes from 'prop-types'

import colors from '../../themes/freshworks/colors'
import { SecondaryText } from '../../typography'

const getInlineMessageStyles = (level) => ({
  color: colors.components.inlineMessage[level],
  padding: '2px',
  lineHeight: '20px'
})

const InlineMessage = ({ level, id, children, className }) => (
  <SecondaryText id={id} style={getInlineMessageStyles(level)}>
    {children}
  </SecondaryText>
)

InlineMessage.propTypes = {
  level: PropTypes.oneOf(['info', 'warning', 'error', 'success']),
  id: PropTypes.string.isRequired
}

InlineMessage.defaultProps = {
  level: 'error'
}

export default InlineMessage
