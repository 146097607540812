import React, { memo, useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { REACT_APP_HEAP_ID, REACT_APP_SENTRY_DNS, REACT_APP_SENTRY_RELEASE } from '../../config'
import AppDataProviderContext from '../../Contexts/AppDataProvider/AppDataProviderContext'
import Loader from '../Loader'

const HeapLoader = memo(() => (
  <Helmet>
    <script type="text/javascript">
      {`
      try{
        window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=t.forceSSL||"https:"===document.location.protocol,a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src=(r?"https:":"http:")+"//cdn.heapanalytics.com/js/heap-"+e+".js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n);for(var o=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],c=0;c<p.length;c++)heap[p[c]]=o(p[c])};
        
        // This Logic is to remove the hash from the Route and send as '********'
        // to Heap
        var pathsToRedact = ['invite', 'reset-password', 'unlock', 'set-password'];
        var REDACT_STR = '********';
        var regExp = new RegExp('\\/+(' + pathsToRedact.join('|') + ')+\\/');
        var PROPS_TO_REDACT = {
          pageview_properties: ['path', 'previous_page'],
          session_properties: ['path', 'referrer'],
          event_properties: ['href']
        };
        function getStringFromMatches(str, matches) {
          return str.substring(0, matches.index) + matches[0] + REDACT_STR
        }
        function redactedValueIfRequired(payload, prop) {
          var str = payload[prop]
          var matches = str.match(regExp);
          if (matches && matches.length) {
            payload[prop] = getStringFromMatches(str, matches);
          }
        }
        heap.load(${REACT_APP_HEAP_ID}, {
          rewrite: function (payload) {
            for (var prop in PROPS_TO_REDACT) {
              var attributes = PROPS_TO_REDACT[prop];
              for (var i = 0; i < attributes.length; i++) {
                if (payload[prop] && payload[prop][attributes[i]]) {
                  redactedValueIfRequired(payload[prop], [attributes[i]]);
                }
              }
            }
            return payload;
          }
        });
      }catch(e){}
      onHeapLoad();
    `}
    </script>
  </Helmet>
))

const SentryLoader = memo(() => (
  <Helmet>
    <script
      src="https://browser.sentry-cdn.com/7.49.0/captureconsole.min.js"
      crossorigin="anonymous"
      onerror="onSentryLoad()"></script>
    <script type="text/javascript" data-testid="sentry-loader-script">
      {`
      function initSentry(){
        try{
          Sentry.init({
            dsn: '${REACT_APP_SENTRY_DNS}',
            release: '${REACT_APP_SENTRY_RELEASE}',
            integrations: [
              new Sentry.Integrations.CaptureConsole({
                levels: ['error']
              })
            ],
            tracesSampleRate: 1.0,
          });
        }catch(e){}
        onSentryLoad();
      }
    `}
    </script>

    <script
      src="https://browser.sentry-cdn.com/5.4.3/bundle.min.js"
      crossOrigin="anonymous"
      onload="initSentry()"
      onerror="onSentryLoad()"></script>
  </Helmet>
))

const TPSLoader = memo(({ children }) => {
  const [isHeapLoading, setIsHeapLoading] = useState(true)
  const [isSentryLoading, setIsSentryLoading] = useState(true)
  if (!window.onHeapLoad) {
    window.onHeapLoad = () => setIsHeapLoading(false)
  }
  if (!window.onSentryLoad) {
    window.onSentryLoad = () => setIsSentryLoading(false)
  }
  useEffect(() => {
    return () => {
      delete window.onHeapLoad
      delete window.onSentryLoad
    }
  }, [])
  return (
    <>
      <HeapLoader />
      <SentryLoader />
      {children({ isTpsLoading: isHeapLoading || isSentryLoading })}
    </>
  )
})

export const ShouldRenderTPS = ({ children }) => {
  const { getDataFromAppStateContext } = useContext(AppDataProviderContext) || {}
  let tpsDisabled = false
  if (getDataFromAppStateContext) {
    tpsDisabled = getDataFromAppStateContext('getOrgConfig.organisationConfig.tpsDisabled', true)
  }

  if (tpsDisabled) {
    return children
  }
  return (
    <TPSLoader>
      {({ isTpsLoading }) => {
        return isTpsLoading ? <Loader /> : children
      }}
    </TPSLoader>
  )
}
