import theme from '../themes/freshworks'
import colors from '../themes/freshworks/colors'

const {
  colors: { text },
  palette,
  typography
} = theme

const getPrimaryTextStyles = ({ textType, textWeight, inline }) => ({
  ...typography.body.regular,
  color: textType ? text[textType] : text.primary,
  ...(textWeight ? typography.text[textWeight] : typography.text.regular),
  display: inline ? 'inline' : 'block'
})

const getSecondaryTextStyles = ({ textType, textWeight }) => ({
  ...typography.body.secondary,
  color: textType ? text[textType] : text.primary,
  ...(textWeight ? typography.text[textWeight] : typography.text.regular)
})

const getHeadingStyle = (tag, { overrideTag, normal }) => {
  if (!tag) return {}
  overrideTag = overrideTag ? overrideTag : tag
  return {
    ...typography.headings[overrideTag],
    fontWeight: normal ? 400 : 600,
    color: text.primary
  }
}

const Link = ({ children, ...restProps }) => {
  return (
    <span
      style={{
        ...typography.body.regular,
        fontWeight: '600',
        cursor: 'pointer',
        color: colors.link
      }}
      {...restProps}>
      {children}
    </span>
  )
}

const getAnchorStyles = ({ light, fontSize }) => {
  const anchorTypography = typography.body.anchor
  const anchorFontSize = anchorTypography.fontSize
  return {
    color: theme.colors.link,
    ...(light ? anchorTypography['light'] : anchorTypography['bold']),
    fontSize:
      fontSize === 'small'
        ? anchorFontSize.small
        : fontSize === 'large'
        ? anchorFontSize.large
        : anchorFontSize.medium
  }
}

const getLinkTextStyles = ({ textWeight, secondary }) => ({
  color: colors.link,
  ...(secondary ? typography.body.secondary : {}),
  ...(textWeight ? typography.text[textWeight] : {})
})

const PrimaryText = ({ children, testId, style, ...props }) => {
  return (
    <p data-testid={testId} style={{ ...getPrimaryTextStyles(props), ...style }}>
      {children}
    </p>
  )
}

const SecondaryText = ({ children, style, ...props }) => {
  return (
    <span style={{ ...getSecondaryTextStyles(props), ...style }} {...props}>
      {children}
    </span>
  )
}

const H1 = ({ children, ...props }) => {
  return <h1 style={getHeadingStyle('h1', props)}>{children}</h1>
}

const H2 = ({ children, style, ...props }) => {
  return <h2 style={{ ...getHeadingStyle('h2', props), ...style }}>{children}</h2>
}

const H3 = ({ children, style, ...props }) => {
  return <h3 style={{ ...getHeadingStyle('h3', props), ...style }}>{children}</h3>
}

const H4 = ({ children, style, ...props }) => {
  return <h4 style={{ ...getHeadingStyle('h4', props), ...style }}>{children}</h4>
}

const H5 = ({ children, style, ...props }) => {
  return <h5 style={{ ...getHeadingStyle('h5', props), ...style }}>{children}</h5>
}

const Anchor = ({ children, customStyles, ...props }) => {
  return (
    <a {...props} style={{ ...getAnchorStyles(props), ...customStyles }}>
      {children}
    </a>
  )
}

const LinkText = ({ children, style, ...props }) => {
  return (
    <span style={{ ...getLinkTextStyles(props), ...style }} {...props}>
      {children}
    </span>
  )
}

export { PrimaryText, SecondaryText, H1, H2, H3, H4, H5, Anchor, LinkText, Link }
