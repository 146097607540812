import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import AuthenticationsFallback from './AuthenticationsFallback'
import { responseDataInterceptorAuthentications, AUTH_CONST } from '../../utils/auth-methods'
import { getUrlPrefix } from '../../utils/utility'
import useFetch from '../../Components/useFetch/useFetch'

const isUrlAbsolute = (url) => /^https?:\/\/|^\/\//i.test(String(url))

const OrgSetupInProgressMessage = React.memo(({ redirectTo: redirectPath }) => {
  const { search } = useLocation()
  useFetch({
    requestName: 'getAllowedLoginMethods',
    transformResponseData: responseDataInterceptorAuthentications,
    onSuccessHandler: () => {
      if (!isUrlAbsolute(redirectPath)) {
        return <Navigate to={redirectPath} />
      }
      window.location.assign(`${redirectPath}${search}`)
    }
  })
  return (
    <Fragment>
      <AuthenticationsFallback
        type={AUTH_CONST.ORG_DOMAIN_BEING_SETUP}
        orgUrl={`${window.location.host}${getUrlPrefix('')}`}
      />
    </Fragment>
  )
})

OrgSetupInProgressMessage.defaultProps = {
  redirectTo: window.location.origin
}

OrgSetupInProgressMessage.propTypes = {
  redirectTo: PropTypes.string
}

export default OrgSetupInProgressMessage
