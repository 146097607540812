import React, { useContext } from 'react'

import AppDataProviderContext from '../../Contexts/AppDataProvider/AppDataProviderContext'
import SessionExists from '../../Pages/SessionExists'
import { delve } from '../../utils/utility'
export const USER_SESSION_DOES_NOT_EXIST = 'USER_SESSION_DOES_NOT_EXIST'

const IfUserSessionExists = ({ children }) => {
  const { getDataFromAppStateContext } = useContext(AppDataProviderContext)
  const getCurrentUserDetails = getDataFromAppStateContext('getCurrentUserDetails', {})
  if (delve(getCurrentUserDetails, 'user.id')) {
    return typeof children === 'function'
      ? children(delve(getCurrentUserDetails, 'user'))
      : children
  } else {
    return null
  }
}

const IfUserSessionAbsent = ({ children }) => {
  const { getDataFromAppStateContext } = useContext(AppDataProviderContext)
  const getCurrentUserDetails = getDataFromAppStateContext('getCurrentUserDetails', {})

  if (getCurrentUserDetails && getCurrentUserDetails === USER_SESSION_DOES_NOT_EXIST) {
    return children
  } else {
    return null
  }
}

const DoesSessionExists = ({ children }) => {
  return (
    <React.Fragment>
      <UserSession.IfUserSessionExists>
        {(user) => <SessionExists userEmail={user.email} />}
      </UserSession.IfUserSessionExists>
      <UserSession.IfUserSessionAbsent>{children}</UserSession.IfUserSessionAbsent>
    </React.Fragment>
  )
}

const UserSession = ({ children }) => {
  const { getDataFromAppStateContext } = useContext(AppDataProviderContext)
  const getCurrentUserDetails = getDataFromAppStateContext('getCurrentUserDetails', {})
  if (delve(getCurrentUserDetails, 'user.id')) {
    return children(getCurrentUserDetails.data)
  } else {
    return null
  }
}
UserSession.IfUserSessionExists = IfUserSessionExists
UserSession.IfUserSessionAbsent = IfUserSessionAbsent
UserSession.DoesSessionExists = DoesSessionExists

export default UserSession
