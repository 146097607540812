import { Anchor, H1, SecondaryText } from '../../typography'
import Dropdown from '../Dropdown/Dropdown'
import Logo from '../Logo/Logo'
import './OneColumnContentBox.css'
import supportedLangauges from '../../config/supportedLanguages.json'
import { useIntl } from '../../Contexts/IntlContext/IntlState'
import LinkBetweenText from '../LinkBetweenText/LinkBetweenText'
import { APP_CONSTANTS } from '../../constants'
import Icon from '../Icon'

export const TOP_MARGIN = 60
const overrideStyles = {
  background: 'transparent',
  lineHeight: '18px',
  boxShadow: 'none'
}

const dropdownMenuStyles = {
  top: '-235px',
  right: '0px',
  textAlign: 'left'
}
const anchorStyles = {
  fontSize: '12px',
  fontWeight: '500',
  margin: '0 2px'
}
export const OneColumnContentSection = ({
  entryPointData,
  children,
  orgLogo = true,
  showPolicies = false,
  buttonNamePath = '',
  showLanguageDropdown = true,
  disableFormFocus = false,
  errorText = '',
  mainHeadingText = '',
  CustomLogoName = '',
  logoRedirectionPath = null
}) => {
  const { toIntlString, setLocale, locale } = useIntl()
  return (
    <div className="one-column-main-container" role="main">
      {orgLogo && (
        <div className="one-column-logo-container">
          {CustomLogoName ? (
            <>
              {logoRedirectionPath ? (
                <a href={logoRedirectionPath}>
                  <Icon name={CustomLogoName} />
                </a>
              ) : (
                <div>
                  <Icon name={CustomLogoName} />
                </div>
              )}
            </>
          ) : (
            <Logo size="large" entryPointData={entryPointData} />
          )}
          {!!mainHeadingText && (
            <div className="one-column-main-heading-text">
              <H1 overrideTag="h3">{toIntlString(mainHeadingText)}</H1>
            </div>
          )}
        </div>
      )}
      <div style={{ marginTop: !orgLogo ? TOP_MARGIN : 0 }}>
        {errorText && (
          <div className="one-column-error-text-container">
            <SecondaryText textType="error" textWeight="bold">
              {errorText}
            </SecondaryText>
          </div>
        )}
        <div
          className={`one-column-content-container${
            disableFormFocus ? ' disable-from-focus' : ''
          }`}>
          {children}
        </div>

        {!disableFormFocus && (
          <div className="one-column-box-shadow-container">
            <div className="one-column-box-shadow"></div>
          </div>
        )}
      </div>
      {!disableFormFocus && (
        <div className="one-column-footer-container">
          {showPolicies && (
            <div className="one-column-policies-container">
              <SecondaryText>
                {toIntlString('common_login.by_clicking', {
                  button_name: toIntlString(buttonNamePath)
                })}
                <LinkBetweenText
                  data-testid="terms_and_privacy_link"
                  translationKey={'common_login.acceptance'}
                  placeHolderReplacementObject={{
                    _terms: {
                      textKey: 'common_login._terms',
                      link: APP_CONSTANTS.FRESHWORKS_TERMS_CONDITIONS,
                      target: '_blank'
                    },
                    _privacy: {
                      textKey: 'common_login._privacy',
                      link: APP_CONSTANTS.FRESHWORKS_PRIVACY,
                      target: '_blank'
                    }
                  }}
                  customStyles={anchorStyles}
                />
              </SecondaryText>
            </div>
          )}
          {showLanguageDropdown && (
            <Dropdown
              name="locale"
              customStyles={overrideStyles}
              selectedValue={supportedLangauges[locale]}
              dropDownLabelText={toIntlString('my_profile.button.choose_language')}
              onChangeHandler={(languageLabel) => {
                const languageChangedTo = Object.keys(supportedLangauges).find(
                  (key) => supportedLangauges[key] === languageLabel
                )
                setLocale(languageChangedTo)
              }}
              menuList={Object.values(supportedLangauges)}
              overrideStylesForMenuContainer={dropdownMenuStyles}
              aria-label={toIntlString('my_profile.button.language_dropdown')}
            />
          )}
        </div>
      )}
    </div>
  )
}

const Height100 = {
  height: '100vh'
}

const OneColumnContentBox = (props) => {
  return (
    <div width={1} style={Height100} {...props}>
      {props.children}
    </div>
  )
}

export default OneColumnContentBox
