import { BrowserRouter, MemoryRouter } from 'react-router-dom'
import { ToastContext } from './Components/Toast'
import IntlState from './Contexts/IntlContext/IntlState'
import { getUrlPrefix } from './utils/utility'
import PropTypes from 'prop-types'

const AppWrapper = ({ children, initialRoute = '', state = {} }) => {
  let initialEntries = []
  const basePathName = getUrlPrefix('/')
  if (Object.keys(state).length === 0) initialEntries = [initialRoute]
  else initialEntries = [{ pathname: initialRoute, state }]
  return (
    <ToastContext>
      <IntlState>
        {/* initialRoute string is sent by TestWrapper,
        because certain tests needs to be start with a initial route */}
        {initialRoute === '' ? (
          <BrowserRouter basename={basePathName}>{children}</BrowserRouter>
        ) : (
          <MemoryRouter initialEntries={initialEntries}>{children}</MemoryRouter>
        )}
      </IntlState>
    </ToastContext>
  )
}

AppWrapper.propTypes = {
  initialRoute: PropTypes.string,
  children: PropTypes.node.isRequired,
  state: PropTypes.object
}

export default AppWrapper
