import { getImageUrl } from '../../config'

const getConditionalProps = (isInformativeIcon, name, altText) =>
  isInformativeIcon ? { alt: `${!!altText ? altText : `${name}-icon`}` } : { role: 'presentation' }

const Icon = ({ width, name, extension = 'svg', isInformativeIcon, altText = '', style }) => {
  const iconContainerStyles = {
    display: 'inline-flex',
    ...(width ? { width, height: width } : {}),
    ...style
  }

  const imageStyles = {
    width,
    height: width
  }
  return (
    <span style={iconContainerStyles}>
      <img
        style={width ? imageStyles : { width: '100%' }}
        src={getImageUrl(`${name}${!!extension ? `.${extension}` : ''}`)}
        {...getConditionalProps(isInformativeIcon, name, altText)}
      />
    </span>
  )
}

export default Icon
