import { useIntl } from '../../Contexts/IntlContext/IntlState'
import theme from '../../themes/freshworks'
import './Loader.css'

import PropTypes from 'prop-types'

const {
  colors: { white, primary }
} = theme

const POSSIBLE_SIZES = {
  small: 16,
  medium: 32,
  big: 40
}

const BORDER_WIDTH = {
  small: 3,
  medium: 5,
  big: 6
}

const getLoaderStyles = ({ size, invert = true }) => ({
  position: 'relative',
  width: `${POSSIBLE_SIZES[size]}px`,
  height: `${POSSIBLE_SIZES[size]}px`,
  border: `${BORDER_WIDTH[size]}px solid ${
    invert ? `rgba(255, 255, 255, 0.4)` : `rgba(44, 92, 197, 0.4)`
  }`,
  borderRadius: '50%'
})

const Loader = (props) => {
  const { toIntlString } = useIntl()
  return (
    <div
      className={`loader-container border-${props.size}${props.invert ? ' invert' : ''}`}
      style={getLoaderStyles(props)}
      role="alert"
      data-testid="loading"
      aria-label={toIntlString('common_login.page_loading')}></div>
  )
}

Loader.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'big']),
  filled: PropTypes.bool,
  invert: PropTypes.bool
}

Loader.defaultProps = {
  size: 'medium',
  filled: false,
  invert: false
}

export default Loader
