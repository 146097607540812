export default function useMocks() {
  const routesToMock = [
    'Login',
    'ActivateUser',
    'ForgotPassword',
    'RequestMagicLink',
    'MagicLinkLogin',
    'SetPassword',
    'ResetPassword',
    'ConfigureMFA',
    'EmailLogin',
    'ForgotDomain',
    'Unlock'
  ]
  let promiseArray = []

  for (let route of routesToMock) {
    promiseArray.push(import(`../Pages/${route}/mocks`))
  }

  const contextsToMock = ['AppDataProvider']

  for (let context of contextsToMock) {
    promiseArray.push(import(`../Contexts/${context}/mocks`))
  }

  return Promise.all(promiseArray)
}
