import { APP_CONSTANTS, isRenderedUnderPrefix } from '../constants'
import { addQueryParams } from '../Pages/Login/LoginUtils'
import translationFile from '../default_translations/en-us.json'
import ENV from '../env.config.json'
import { AUTH_CONST } from './auth-methods'

const OAUTH_URL_PATH = '/oauth/authorize'
const OAUTH_CONSENT_PATH = '/oauth/consent'
const OAUTH_URL_PARAM = { response_type: 'code' }
const API_HOST = ENV.HOST
const API_NAMESPACE = ENV.NAMESPACE

const BASE_URL = `${API_HOST}${API_NAMESPACE}`
const LOGOUT_URL = `/logout`

const REGEX_DEFAULTS = {
  DELIMITERS: {
    START: '^',
    END: '$'
  },
  NEGATE: '^',
  MATCH: {
    ZERO_MORE: '*',
    ONE_MORE: '+'
  },
  SET: {
    START: '[',
    END: ']'
  }
}

const NAME_FIELD_BLACK_LISTED_CHARS = '.<>&=();%":/'
// the following string construct translates to '^[^<>&=()%".:;/]*$'
const NAME_FIELD_REGEX_STR =
  REGEX_DEFAULTS.DELIMITERS.START +
  REGEX_DEFAULTS.SET.START +
  REGEX_DEFAULTS.NEGATE +
  NAME_FIELD_BLACK_LISTED_CHARS +
  REGEX_DEFAULTS.SET.END +
  REGEX_DEFAULTS.MATCH.ZERO_MORE +
  REGEX_DEFAULTS.DELIMITERS.END
const NAME_FIELD_REGEX = new RegExp(NAME_FIELD_REGEX_STR)

const NAME_FIELD_CONFIG = {
  NAME_FIELD_BLACK_LISTED_CHARS,
  NAME_FIELD_REGEX
}

const passwordErrorToKeyList = {
  PASSWORD_POLICY_INVALID_HISTORY: 'historyCount',
  PASSWORD_CONTAINS_PERSONAL_INFORMATION: 'noPersonalInfo'
}

const logger = ['log', 'warn', 'error'].reduce((acc, curr, i) => {
  acc[curr] = process.env.NODE_ENV === APP_CONSTANTS.NODE_ENV.DEV ? console[curr] : () => {}
  return acc
}, {})

const delve = (obj, key, def, p, undef) => {
  key = key.split ? key.split('.') : key
  for (p = 0; p < key.length; p++) {
    obj = obj ? obj[key[p]] : undef
  }
  return obj === undef ? def : obj
}

function getQueryParams(name, queryParam = '') {
  var search = new URLSearchParams(queryParam || window.location.search)
  return search.get(name)
}

const capitaliseFirstLetter = (str = '') =>
  `${str.substring(0, 1).toUpperCase()}${str.substring(1)}`

const getBooleanQueryParam = (paramKey = '', queryParam) =>
  (getQueryParams(paramKey, queryParam) || '').toLowerCase() === String(true)

const getHostFromUrl = (url) => {
  let host = null
  try {
    host = new URL(url).host
  } catch (e) {
    /* tslint:disable */
    logger.error(`Invalid URL: ${url} `)
    /* tslint:disable */
  }
  return host
}

const getAllQueryParamsExcept = (params = [], searchParams) => {
  const search = new URLSearchParams(searchParams || window.location.search)
  params.forEach((param) => {
    search.delete(param)
  })
  return search.toString()
}

const setQueryParam = (name, value) => {
  //This will replace the param value if it is already present
  const search = new URLSearchParams(window.location.search)
  search.set(name, value)
  return search.toString()
}

function _getOAuthUrl(queryParams, isConsent) {
  return isConsent
    ? `${OAUTH_CONSENT_PATH}?${queryParams}`
    : `${OAUTH_URL_PATH}?${addQueryParams(queryParams, OAUTH_URL_PARAM)}`
}

function callOauthEndpoint(search, isConsent = false) {
  // This is to prevent the yet another re-authorize initiation
  const filteredSearchParams = getAllQueryParamsExcept(
    [APP_CONSTANTS.STEP_UP_AUTH_TYPE_PARAM_KEY],
    search
  )

  window.location.assign(
    `${APP_CONSTANTS.DEFAULT_REDIRECT_PATH}${_getOAuthUrl(filteredSearchParams, isConsent)}`
  )
}

function snakeToCamel(string) {
  let splitStringArr = string.split('_')
  let builtStr = splitStringArr.reduce((acc, curr, i) => {
    curr = i !== 0 ? curr[0].toUpperCase() + curr.slice(1) : curr
    return acc + curr
  }, '')
  return builtStr
}

const getUrlPrefix = (defaultPrefix) => (isRenderedUnderPrefix ? ENV.PREFIX : defaultPrefix)

function camelizeKeysInObj(res) {
  let response
  try {
    response = JSON.parse(res)
  } catch {
    response = res
  }

  let parentKeys = Object.keys(response)

  parentKeys.forEach((key) => {
    let currentObj = response[key]
    delete response[key]
    let newKey = snakeToCamel(key)
    response[newKey] = currentObj

    if (response[newKey] && typeof response[newKey] === 'object') {
      camelizeKeysInObj(response[newKey])
    }
  })

  return response
}

function decamelizeKeys(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj // Return as is if not an object or array
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => decamelizeKeys(item)) // Process array elements recursively
  }

  const decamelize = (str) => {
    // Convert camelCase string to snake_case
    return str.replace(/([a-z0-9])([A-Z])/g, '$1_$2').toLowerCase()
  }

  const newObj = {}
  Object.keys(obj).forEach((key) => {
    const newKey = decamelize(key) // Convert key to snake_case
    const value = obj[key]
    newObj[newKey] = typeof value === 'object' ? decamelizeKeys(value) : value // Recursively process nested objects
  })

  return newObj
}

const getRedirectUriFromError = (err) => {
  const { data: { details = [] } = {} } = err
  for (let detail of details) {
    if (!!detail.errorMap && !!detail.errorMap[APP_CONSTANTS.REDIRECT_URI]) {
      return detail.errorMap[APP_CONSTANTS.REDIRECT_URI]
    }
  }
  return APP_CONSTANTS.DEFAULT_REDIRECT_PATH
}

function heapTrack(eventName, eventProperties = {}) {
  if (eventName && window.heap && typeof window.heap.track === 'function') {
    window.heap.track(eventName, eventProperties)
  }
}

const handle400ErrorForHashes = (err, errorToHandle, fallbackError, redirectUri) => {
  const { data: { error } = {} } = err
  if (errorToHandle.indexOf(error) > -1) {
    window.location.assign(redirectUri || getRedirectUriFromError(err))
    throw new Error(error)
  }
  if (!!fallbackError) {
    throw new Error(fallbackError)
  }
}

const getObjectDifference = (initialObj, submittingObject) => {
  let diffs = {}
  Object.keys(submittingObject).forEach((key) => {
    if (initialObj[key] !== submittingObject[key]) {
      diffs[key] = submittingObject[key]
    }
  })
  return diffs
}

function getServerErrorForPassword(errorList = []) {
  const errorKeys = []
  errorList.forEach((item) => {
    if (passwordErrorToKeyList[item]) {
      errorKeys.push(passwordErrorToKeyList[item])
    }
  })
  return errorKeys
}

function getErrorList(errorData = {}) {
  const { details } = errorData,
    errorList = []
  if (details && details.length > 0) {
    details.map((error) => {
      const { field_violations } = error
      if (field_violations && field_violations.length > 0) {
        field_violations.map(({ field }) => {
          errorList.push(field && field.toUpperCase())
        })
      }
    })
  }

  return {
    errorList
  }
}

function getServerErrors({ status, data }, translationPath) {
  let errorCode = []

  switch (status) {
    case 0:
      errorCode = ['error.connection_failure']
      break
    case 401:
      errorCode = ['error.unauthorized']
      break
    case 403:
      errorCode = ['error.forbidden_admin_access']
      break
    case 500:
    case 503:
      throw new Error('SERVER_ERROR')
      break
    default:
      /**
       * Though DataManager is setting `errorList` during error response
       * we are explicitly deriving `errorList` from `data` yet again since this utility method is
       * also used in components that do not use DataManager for HTTP calls - like Profile
       */

      const { errorList } = getErrorList(data)

      if (!!errorList.length) {
        const objectOfServerError = delve(translationFile, `${translationPath}.server_errors`, {})
        errorCode = errorList.map((error) =>
          !!objectOfServerError[error]
            ? `${translationPath}.server_errors.${error}`
            : 'error.generic_server_error'
        )
      } else {
        errorCode = ['error.generic_server_error']
      }
  }

  return errorCode
}

// utility to check and return if user has staged email
// otherwise primary email is returned
// `staged email`: interim state when user's email is updated by
// org admin but updated email is not activated by the user yet
const getUserEmail = (user) => user.stagedEmail || user.email

export const getOrgDomainFromContext = ({ getDataFromAppStateContext }) =>
  getDataFromAppStateContext('getOrgConfig.organisationDomain', window.location.host)

const handleAllowedLoginMethodsError = (err) => {
  const errors = {
    INVALID_HASHCODE: 'INVALID_HASHCODE',
    HASHCODE_EXPIRED: 'HASHCODE_EXPIRED',
    SERVER_ERROR: 'SERVER_ERROR',
    NETWORK_PROBLEM: 'NETWORK_PROBLEM'
  }

  const { status, data: { error } = {} } = err
  switch (status) {
    case 500:
    case 503:
      throw new Error(errors.SERVER_ERROR)
    case 400:
      handle400ErrorForHashes(
        err,
        [errors.INVALID_HASHCODE, errors.HASHCODE_EXPIRED],
        errors.INVALID_HASHCODE
      )
    default:
      //Error is handled at DataBoundary at AppDataProvider
      throw new Error(AUTH_CONST.AUTHENTICATIONS_METHOD_FAILED)
  }
}

function isBrowserIE() {
  var ua = window.navigator.userAgent

  var msie = ua.indexOf('MSIE ')
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10) ? true : false
  }

  var trident = ua.indexOf('Trident/')
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:')
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10) ? true : false
  }

  var edge = ua.indexOf('Edge/')
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10) ? true : false
  }

  // other browser
  return false
}

const getAPIURL = (resourceURL) => `${getUrlPrefix('')}${BASE_URL}${resourceURL}`

const getLogoutUrlWithPostLogoutRedirectUrl = (postLogoutRedirectURI = '', orgUrlOrigin = '') =>
  `${orgUrlOrigin}${getAPIURL(LOGOUT_URL)}?${APP_CONSTANTS.POST_LOGOUT_REDIRECT_URI}=${
    postLogoutRedirectURI || orgUrlOrigin || window.location.href
  }`

const queryParamsToObject = (search) => {
  let allUrlQueryParams = {}
  for (let [key, value] of new URLSearchParams(search).entries()) {
    allUrlQueryParams[key] = value
  }
  return allUrlQueryParams
}

export {
  getBooleanQueryParam,
  getQueryParams,
  getHostFromUrl,
  capitaliseFirstLetter,
  callOauthEndpoint,
  camelizeKeysInObj,
  handle400ErrorForHashes,
  getUserEmail,
  delve,
  logger,
  getServerErrorForPassword,
  getServerErrors,
  getObjectDifference,
  snakeToCamel,
  getUrlPrefix,
  NAME_FIELD_CONFIG,
  handleAllowedLoginMethodsError,
  getErrorList,
  getAllQueryParamsExcept,
  setQueryParam,
  isBrowserIE,
  getRedirectUriFromError,
  heapTrack,
  getLogoutUrlWithPostLogoutRedirectUrl,
  queryParamsToObject,
  decamelizeKeys
}
