import React from 'react'
import PropTypes from 'prop-types'

import { Anchor, SecondaryText } from '../../typography'
import { useIntl } from '../../Contexts/IntlContext/IntlState'

const LinkBetweenText = ({ translationKey, placeHolderReplacementObject = {}, ...rest }) => {
  const { toIntlString } = useIntl()

  const placeHoldersArray = Object.keys(placeHolderReplacementObject)
  let placeHolderKeysObject = {}
  placeHoldersArray.forEach((placeHolder) => {
    placeHolderKeysObject[placeHolder] = placeHolder
  })

  let message = toIntlString(translationKey, placeHolderKeysObject)
  let messageParts = []
  let tempParts
  placeHoldersArray.forEach((placeHolder, index) => {
    tempParts = message.split(placeHolder)
    messageParts.push(tempParts[0])
    if (index + 1 === placeHoldersArray.length) {
      messageParts.push(tempParts[1])
    } else {
      message = tempParts[1]
    }
  })

  return (
    <>
      {messageParts[0]}
      {placeHoldersArray.map((placeHolder, index) => {
        const { textKey, link, target, onClick } = placeHolderReplacementObject[placeHolder]
        let linkAttr = { target, onClick: onClick || (() => {}) }
        if (link) linkAttr.href = link
        return (
          <>
            <Anchor {...linkAttr} {...rest}>
              <SecondaryText textType="link" textWeight="bold">
                {toIntlString(textKey)}
              </SecondaryText>
            </Anchor>
            {messageParts[index + 1]}
          </>
        )
      })}
    </>
  )
}

LinkBetweenText.propTypes = {
  translationKey: PropTypes.string,
  /*
   NOTE: make sure the number of keys in the placeHolderReplacementObject is equal to redirectTo array length
  */
  placeHolderReplacementObject: PropTypes.object,
  redirectTo: PropTypes.arrayOf(PropTypes.string)
}

export default LinkBetweenText
