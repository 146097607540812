import React, { useState } from 'react'
import './FreshworksStyledInput.css'
import theme from '../../themes/freshworks'
import InlineMessage from '../InlineMessage/InlineMessage'
import { getImageUrl } from '../../config'
import Icon from '../Icon'

const { colors, typography, palette } = theme
const getInputContainerStyles = (
  isFocused,
  disableFocus = false,
  showError = false,
  disabled = false,
  readOnly = false
) => ({
  ...(isFocused && !disableFocus
    ? !showError
      ? {
          border: 'none',
          boxShadow: `0 0 0 2px ${colors.pseudo.focus}`,
          transition: 'box-shadow 0.3s ease-in-out'
        }
      : {}
    : {
        ':hover': {
          borderColor: colors.border.hover,
          transition: 'border-color 0.3s ease-in-out'
        }
      }),
  ...(showError
    ? {
        boxShadow: 'none',
        borderColor: colors.border.error,
        transition: 'border-color 0.3s ease-in-out',
        ':hover': { borderColor: colors.border.error }
      }
    : {}),
  ...(disabled || readOnly
    ? {
        cursor: 'not-allowed',
        pointerEvents: 'none',
        borderColor: colors.border.disabled,
        color: 'red'
      }
    : {})
})

const getInputStyle = ({ _type }) => {
  const styleObject = typography.input[_type]
  if (styleObject)
    return {
      ...typography.input[_type]
    }

  return typography.input.text
}

const getLabelStyles = (disabled) => ({
  width: '100%',
  ...typography.input.label,
  cursor: 'pointer',
  color: colors.label.primary,
  ...(disabled ? { cursor: 'not-allowed' } : {})
})

const getLabelAfterStyles = {
  padding: '2px',

  color: palette.p[800]
}

const checkedIconStyles = {
  display: 'inline',
  position: 'absolute',
  left: '2px',
  top: '2px'
}

export const FreshworksStyledCheckBox = ({ label, name, onChange, ...restProps }) => {
  const [isChecked, setIsChecked] = useState(false)
  return (
    <div style={{ position: 'relative' }}>
      {isChecked && <Icon style={checkedIconStyles} width="12px" name="correct-light" />}
      <input
        onChange={(e) => {
          setIsChecked(e.target.checked)
          onChange(e)
        }}
        className="checkbox-input"
        id={name}
        name={name}
        data-testid={name}
        type="checkbox"
        {...restProps}
      />

      <div style={{ display: 'inline', paddingLeft: '8px' }}>
        <label style={getLabelStyles()} htmlFor={name}>
          {label}
        </label>
      </div>
    </div>
  )
}

const FreshworksStyledInput = ({
  children,
  label,
  small,
  name,
  disabled,
  required,
  errors,
  disableFocus,
  LeadingItem,
  TrailingItem,
  overrideStyles = {},
  inputAttrs = {},
  standalone = false,
  inputStyles = {},
  containerStyles = {},
  onBlur = () => {},
  setFocused = null,
  ...props
}) => {
  const showError = errors && errors[name] && errors[name].dirty && errors[name].error
  const [isFocused, setIsFocused] = useState(false)

  return (
    <>
      <div style={containerStyles}>
        {!!label && (
          <>
            <label style={getLabelStyles(disabled)} htmlFor={name}>
              {label}
            </label>
            {required && label && <span style={getLabelAfterStyles}>*</span>}
          </>
        )}
        <div style={{ margin: '4px' }} />
        <div
          style={{
            ...getInputContainerStyles(isFocused, disableFocus, showError, disabled),
            ...overrideStyles
          }}
          className={`styled-input-container${small ? ' small' : ''}`}>
          {typeof LeadingItem === 'object' && LeadingItem}
          <input
            name={name}
            id={name}
            style={{ ...getInputStyle({ _type: props.type }), ...inputStyles }}
            onFocus={() => {
              setIsFocused(true)
              setFocused && setFocused(true)
            }}
            onBlur={(e) => {
              setIsFocused(false)
              setFocused && setFocused(false)
              onBlur(e)
            }}
            className="styled-input-field"
            disabled={disabled}
            aria-disabled={disabled}
            aria-required={required}
            data-testid={name}
            {...props}
            {...inputAttrs}
          />
          {typeof TrailingItem === 'object' && TrailingItem}
        </div>
        {showError && !!errors[name].message && (
          <div>
            <InlineMessage level="error" id={`${name}-error_msg`}>
              {errors[name]?.message}
            </InlineMessage>
          </div>
        )}
        {!standalone && <div style={{ marginBottom: '20px' }}></div>}
      </div>
    </>
  )
}

FreshworksStyledInput.defaultProps = {
  type: 'text',
  small: false
}
export default FreshworksStyledInput
