import React, { Component } from 'react'

import { ServerError } from '../Errors'

export default class TopErrorBoundary extends Component {
  state = {
    error: null
  }

  static getDerivedStateFromError({ message }) {
    // catch and gracefully error out all
    // unhandled errors within the app
    return {
      error: message
    }
  }

  render() {
    const { error } = this.state
    const { children } = this.props

    if (error) {
      !!window.heap &&
        window.heap.track('Server Error Page', {
          error
        })
      const errorId = !!window.Sentry && window.Sentry.captureException(new Error(error))
      return <ServerError errorId={errorId} error={error} />
    } else {
      return children
    }
  }
}
