// this will be determined during the build time
let RELEASE_SCOPE, ENVIRONMENT
const REACT_APP_CDN_URL = process.env.REACT_APP_CDN_URL || '/'
const RECAPTCHA_KEY = process.env.REACT_APP_GRECAPTCHA_KEY
const GENERIC_NETWORK_ERRORS = ['SERVER_ERROR']
const FRESHID_CLIENT_ID = process.env.REACT_APP_FRESHID_CLIENT_ID
const REACT_APP_SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS
const REACT_APP_HEAP_ID = process.env.REACT_APP_HEAP_ID
const REACT_APP_SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE

const getImageUrl = (name) => {
  return `${REACT_APP_CDN_URL}assets/images/${name}`
}

export const DEFAULT_LANGUAGE = 'en-US'

export {
  RELEASE_SCOPE,
  REACT_APP_CDN_URL,
  GENERIC_NETWORK_ERRORS,
  RECAPTCHA_KEY,
  FRESHID_CLIENT_ID,
  getImageUrl,
  REACT_APP_HEAP_ID,
  REACT_APP_SENTRY_DNS,
  REACT_APP_SENTRY_RELEASE
}
