import React from 'react'

import { useIntl } from '../../Contexts/IntlContext/IntlState'
import { OneColumnContentBox, OneColumnContentSection } from '../OneColumnContentBox'
import { H1, PrimaryText } from '../../typography'
import { getImageUrl } from '../../config'

export default function ServerError({ errorId, error }) {
  console.error('SERVER ERROR : Oops! something went wrong!, ', error)
  const heapUserId = window.heap && (window.heap.identity || window.heap.userId)
  const { toIntlString } = useIntl()
  return (
    <OneColumnContentBox>
      <OneColumnContentSection disableFormFocus orgLogo={false}>
        <div style={{ textAlign: 'center' }}>
          <H1>{toIntlString('link_expired.title')}</H1>
        </div>
        <div style={{ textAlign: 'center' }} mt={3} mb={4}>
          <img
            width="75%"
            src={getImageUrl('default_error_fallback.png')}
            alt={toIntlString('error.error')}
          />
        </div>
        <div style={{ textAlign: 'center' }} mt={3} mb={4}>
          {errorId && (
            <PrimaryText textType="error">
              {`${toIntlString('error.error_id')} ${errorId}`}
            </PrimaryText>
          )}
          {heapUserId && (
            <PrimaryText textType="error">
              {`${toIntlString('error.error_code')} ${heapUserId}`}
            </PrimaryText>
          )}
          <br />
          <PrimaryText>{toIntlString('error.unknown_error')}</PrimaryText>
          <PrimaryText testId="email-suggestion-error">
            {toIntlString('error.email_suggestion')}
          </PrimaryText>
          <a href="mailTo:support@freshworks.com">support@freshworks.com</a>
        </div>
      </OneColumnContentSection>
    </OneColumnContentBox>
  )
}
