import ENV from './env.config.json'

/**
 * @Desc - handles the case when URL loaded is -
 *    {productDomain}{prefix}/some-other-path
 *                  or
 *    {productDomain}{prefex}
 *                  or
 *    {orgDomain}/organisation
 */
export const isRenderedUnderPrefix =
  window.location.pathname.indexOf(`${ENV.PREFIX}/`) === 0 ||
  window.location.pathname === `${ENV.PREFIX}`
const DEFAULT_REDIRECT_PAGE = '/'
const LOGIN_PATHNAME = '/login'
const DEFAULT_REDIRECT_PATH = `${window.location.origin}${isRenderedUnderPrefix ? ENV.PREFIX : ''}`
const TOTP = 'TOTP'

export const APP_CONSTANTS = {
  SCREEN_SIZES: {
    md: 768,
    lg: 992,
    xl: 1200
  },
  WEB_STORAGE: {
    LOCAL_STORAGE: 'localStorage'
  },
  ERROR: 'error',
  NODE_ENV: {
    DEV: 'development',
    TEST: 'test'
  },
  // local storage key for storing language option
  USER_LANGUAGE_PATH: 'freshworks-common-login:language-code',
  LOGIN_PATHNAME,
  ORG_PATHS: {
    SET_ORG_SESSION: 'setSessionCookie'
  },
  EMAIL_LOGIN_PATHNAME: '/email-login',
  DOMAIN_LOGIN_PATHNAME: '/domain-login',
  LOGIN_HINT_PARAM_KEY: 'login_hint',
  FRESHWORKS: 'Freshworks',
  GLOBAL_SEARCH_API_ENUM: {
    SUCCESS: 'SUCCESS',
    MULTIPLE_ORGANISATION: 'MULTIPLE_ORGANISATION',
    EMAIL_INITIATED: 'EMAIL_INITIATED'
  },
  ERROR_STRINGS: {
    UNEXPECTED_RESPONSE: 'UNEXPECTED_RESPONSE'
  },
  CUSTOMIZED_IMAGES_TYPES: {
    ORG_LOGO: 'ORG_LOGO',
    ORG_FAVICON: 'ORG_FAVICON'
  },
  // TRANSLATION_LOCATION is created on the fly on build time
  TRANSLATION_LOCATION: 'assets/translations',
  RTL_LANGUAGES_LCID: ['he-IL', 'ar-SA'],
  FRESHWORKS_TERMS_CONDITIONS: 'https://www.freshworks.com/terms/',
  FRESHWORKS_PRIVACY: 'https://www.freshworks.com/privacy/',
  DEFAULT_REDIRECT_PATH: DEFAULT_REDIRECT_PATH,
  DEFAULT_REDIRECT_URL: `${DEFAULT_REDIRECT_PATH}${DEFAULT_REDIRECT_PAGE}`,
  LOGIN_REDIRECT_URL: `${DEFAULT_REDIRECT_PATH}${LOGIN_PATHNAME}`,
  FRESHWORKS_HOME: 'https://www.freshworks.com',
  DEFAULT_LANGUAGE: 'en-US',
  AUTHENTICATION_METHODS: {
    PASSWORD: 'PASSWORD',
    MAGICLINK: 'MAGICLINK',
    GOOGLE: 'GOOGLE',
    SSO: 'SSO',
    SHOPIFY: 'SHOPIFY'
  },
  // @TODO remove these and rely on SecuritySetting/utils once Old Security is retired
  SSO_LOGIN_METHODS: {
    OIDC: 'OIDC',
    OAUTH: 'OAUTH',
    SAML: 'SAML',
    JWT_SSO: 'JWT_SSO'
  },
  DEFAULT_REDIRECT_PAGE,
  RE_AUTH_PATHNAME: '/login/reauth',
  STEP_UP_AUTH_TYPE_PARAM_KEY: 'step_up_auth_type',
  REDIRECT_URI: 'redirect_uri',
  RECAPTCHA_LOAD_WAIT_TIME: 10000,
  SLUG_PARAM_KEY: 'slug',
  UPDATE_PASSWORD: {
    PASSWORD_EXPIRED: 'PASSWORD_EXPIRED'
  },
  POST_LOGOUT_REDIRECT_URI: 'post_logout_redirect_uri',
  MFA: {
    DEFAULT_MFA_TYPE: TOTP,
    TYPES: {
      TOTP,
      EMAIL: 'EMAIL'
    },
    STATE: {
      DISABLED: 'DISABLED',
      ACTIVATED: 'ACTIVATED'
    },
    FROM_LOGIN: 'fromLogin',
    FROM_INVITE: 'fromInvite',
    MFA_REQUIRED: 'MFA_REQUIRED',
    MFA_CONFIGURATION_REQUIRED: 'MFA_CONFIGURATION_REQUIRED',
    MFA_CONFIGURATION_OPTIONAL: 'MFA_CONFIGURATION_OPTIONAL',
    SUPPORT_LINK:
      'https://support.freshworks.com/support/solutions/articles/50000001025-using-two-factor-authentication'
  },
  USER_PROFILE_RESTRICTION_LEVELS: {
    RESTRICTION_ALLOW_ALL: 'RESTRICTION_ALLOW_ALL',
    RESTRICTION_ALLOW_NONE: 'RESTRICTION_ALLOW_NONE',
    RESTRICTION_ALLOW_SOME: 'RESTRICTION_ALLOW_SOME'
  },
  RESPONSE_STATUSES: {
    ERRORS: {
      BAD_REQUEST: 400,
      NOT_FOUND: 404
    }
  },
  FORM_FIELD_CONSTANTS: {
    OLD_PASSWORDS_MAX_LENGTH: 80,
    PASSWORD_LENGTH: {
      MIN: 8,
      MAX: 80
    },
    LENGTH_VALIDATION_KEY: 'size'
  },
  ORIGIN: 'origin',
  GLOBAL_USER_SEARCH: 'GLOBAL_USER_SEARCH',
  PASSWORD_POLICY: {
    PASSWORD_EXPIRY_DAYS: 'passwordExpiryDays',
    NO_PERSONAL_INFO: 'noPersonalInfo',
    HISTORY_COUNT: 'historyCount',
    SAME_AS_OLD_PASSWORD: 'sameAsOldPassword',
    VALIDATED_IN: {
      SERVER: 'SERVER',
      CLIENT: 'CLIENT'
    },
    PASSWORD_POLICY_LEVELS: {
      LOW: 'LOW',
      MEDIUM: 'MEDIUM',
      HIGH: 'HIGH',
      CUSTOM: 'CUSTOM'
    },
    PASSWORD_ENFORCEMENT_POLICY: {
      IMMEDIATE: 'IMMEDIATE',
      NEXT_LOGIN: 'NEXT_LOGIN',
      PASSWORD_CHANGE: 'PASSWORD_CHANGE'
    }
  }
}
