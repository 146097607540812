import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import useMocks from './utils/useMocks'
import { applyMock } from './Components/useFetch/useFetch'
import { APP_CONSTANTS } from './constants'

const applyMocks = () => {
  if (process.env.NODE_ENV === APP_CONSTANTS.NODE_ENV.DEV) {
    return new Promise(function (resolve) {
      useMocks().then((mocks) => {
        const mockInstance = applyMock()
        for (let mock of mocks) {
          mock.default(mockInstance)
        }
        resolve()
      })
    })
  } else {
    return Promise.resolve()
  }
}

applyMocks().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root'))
  root.render(<App />)
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
