import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Icon from '../../Components/Icon'
import Loader from '../../Components/Loader'
import Logo from '../../Components/Logo/Logo'
import Fade from '../../Components/Transitions/Fade'
import useFetch from '../../Components/useFetch/useFetch'
import { useIntl } from '../../Contexts/IntlContext/IntlState'
import colors from '../../themes/freshworks/colors'
import { H2 } from '../../typography'
import { errors } from './serverErrors'

const TEXT_ALIGN_CENTER = { textAlign: 'center' }
const containerStyles = {
  backgroundColor: 'white',
  height: '100vh',
  width: '100%',
  paddingTop: '128px'
}

const Unlock = React.memo(() => {
  const { hash } = useParams()
  const { toIntlString } = useIntl()
  const [loadUnlockPage, setLoadUnlockPage] = useState(false)
  useFetch({
    requestName: 'unlockUser',
    pathFragments: [hash],
    onSuccessHandler: () => {
      setLoadUnlockPage(true)
    },
    onErrorHandler: ({ status, data: { error } = {} }) => {
      switch (status) {
        case 500:
        case 503:
          throw new Error(errors.SERVER_ERROR)
        case 400:
          if (error === errors.HASHCODE_EXPIRED) throw new Error(errors.HASHCODE_EXPIRED)
          else if (error === errors.INVALID_HASHCODE) throw new Error(errors.INVALID_HASHCODE)
          break
        default:
          switch (error) {
            default:
              throw new Error(errors.INVALID_HASHCODE)
          }
      }
    }
  })

  return (
    <div style={containerStyles}>
      <Fade in={!loadUnlockPage}>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '128px' }}>
          <Loader />
        </div>
      </Fade>
      <Fade in={loadUnlockPage}>
        <div style={{ ...TEXT_ALIGN_CENTER, paddingTop: '16px' }}>
          <Logo size="xlarge" />
        </div>
        <div style={{ ...TEXT_ALIGN_CENTER, marginTop: '64px' }}>
          <Icon width={40} name="success_logo" />
        </div>
        <div style={{ ...TEXT_ALIGN_CENTER, marginTop: '8px' }}>
          <H2 style={{ color: colors.text.label }}>{toIntlString('unlock.success.title')}</H2>
        </div>
      </Fade>
      {/* TODO :: {!loading && <InteractivityBeacon />} */}
    </div>
  )
})

export default Unlock
