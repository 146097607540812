import { useState } from 'react'
import { APP_CONSTANTS } from '../../constants'
import theme from '../../themes/freshworks'
import FreshworksStyledInput from '../FreshworksStyledInput'
import Icon from '../Icon/Icon'
import TooltipText from '../Tooltip/TooltipText'
import './Dropdown.css'

export const DROPDOWN_TYPES = {
  BUTTON: 'button',
  INPUT: 'input'
}

const containerStyle = {
  height: '0px',
  width: '100%'
}

const { colors, typography } = theme

const getIconName = (isOpen, disabled) => {
  let iconName = isOpen ? 'chevron-down' : 'chevron-up'

  if (disabled) {
    iconName += '-disabled'
  }

  return iconName
}

const getDropdownItemsStyles = ({ isSelectedIndex, canHighlight, disabled = false }) => ({
  color: colors.text.primary,
  margin: '8px',
  borderRadius: '2px',
  padding: '10px 8px',
  cursor: 'pointer',
  ...typography.body.secondary,
  ...(canHighlight ? { backgroundColor: colors.background.hover } : { backgroundColor: 'none' }),
  ...(!disabled
    ? isSelectedIndex
      ? { fontWeight: 600, color: colors.link, backgroundColor: colors.background.selected }
      : {}
    : {
        color: colors.text.secondary,
        cursor: 'default'
      })
})

const getDropdownIconStyles = (label) => ({
  position: 'absolute',
  top: !!label ? '25px' : 0,
  right: 0,
  padding: '4px 10px 6px 4px',
  cursor: 'pointer',
  backgroundColor: 'transparent'
})

const DropdownInput = ({
  setShouldOpenDropDown,
  labelText,
  name,
  shouldOpenDropDown,
  selectedItem,
  setSelectedItem,
  setFilteredMenuList,
  menuList,
  selectedValue,
  disabled,
  disableFocus,
  disabledTooltipText = ''
}) => {
  return (
    <>
      <div style={{ position: 'relative' }}>
        <TooltipText
          style={{ top: '-25%' }}
          forceDisable={!disabled}
          tooltipText={disabledTooltipText}>
          <FreshworksStyledInput
            name={name}
            disabled={disabled}
            disableFocus={disableFocus}
            inputStyles={{ padding: '6px 20px 6px 12px' }}
            standalone
            label={labelText}
            onBlur={(e) => {
              const value = e.target.value
              const isValidItem = menuList.some((item) => item === value)
              if (!isValidItem) setSelectedItem(selectedValue)
            }}
            onClick={() => {
              setShouldOpenDropDown(!shouldOpenDropDown)
            }}
            errors={{}}
            small={true}
            data-testid={name}
            onChange={(e) => {
              const value = e.target.value
              setSelectedItem(value)
              setFilteredMenuList(() => {
                if (!value) return menuList
                return menuList.filter((element) => element.includes(value))
              })
            }}
            value={selectedItem}
          />
        </TooltipText>
        {!disabled && (
          <div
            onClick={() => setShouldOpenDropDown(!shouldOpenDropDown)}
            style={getDropdownIconStyles(labelText)}>
            <Icon width="8px" name={shouldOpenDropDown ? 'chevron-down' : 'chevron-up'} />
          </div>
        )}
      </div>
    </>
  )
}

const Dropdown = ({
  type,
  name,
  menuList = [],
  header,
  selectedValue,
  disabled,
  disableFocus,
  disabledTooltipText = '',
  onChangeHandler,
  customStyles = {},
  overrideStylesForMenuContainer = {},
  labelText = null,
  dropDownLabelText,
  ...restProps
}) => {
  const [shouldOpenDropDown, setShouldOpenDropDown] = useState(false)
  const [selectedItem, setSelectedItem] = useState(selectedValue)
  const [filteredMenuList, setFilteredMenuList] = useState(menuList)

  const changeHandler = (ele) => {
    setSelectedItem(ele)
    setShouldOpenDropDown(false)
    onChangeHandler(ele)
  }
  return (
    <>
      {type === DROPDOWN_TYPES.INPUT && (
        <DropdownInput
          disabled={disabled}
          disableFocus={disableFocus}
          disabledTooltipText={disabledTooltipText}
          setShouldOpenDropDown={setShouldOpenDropDown}
          labelText={labelText}
          shouldOpenDropDown={shouldOpenDropDown}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
          setFilteredMenuList={setFilteredMenuList}
          menuList={menuList}
          selectedValue={selectedValue}
          name={name}
          {...restProps}
        />
      )}
      {type === DROPDOWN_TYPES.BUTTON && (
        <button
          data-testid={name}
          style={customStyles}
          className="dropdown-button"
          onBlur={() => {
            // Since we are giving blur action to the button
            // Dropdown is closing with item getting selected
            // So added a timeout
            setTimeout(() => {
              setShouldOpenDropDown(false)
            }, 180)
          }}
          onClick={() => {
            setShouldOpenDropDown(!shouldOpenDropDown)
          }}
          {...restProps}>
          {selectedItem}
          <span style={{ paddingLeft: '8px' }}>
            <Icon name={getIconName(shouldOpenDropDown)} width={8} />
          </span>
        </button>
      )}

      {shouldOpenDropDown && (
        <div style={containerStyle}>
          <ul style={overrideStylesForMenuContainer} className="dropdown-menu-container">
            {dropDownLabelText && (
              <li
                disabled
                className="dropdown-menu-item"
                style={getDropdownItemsStyles({
                  isSelectedIndex: false,
                  disabled: true
                })}>
                {dropDownLabelText}
              </li>
            )}
            {filteredMenuList.map((ele, index) => {
              const canHighlightSelectedIndex = filteredMenuList.indexOf(selectedItem) === index
              return (
                <li
                  onClick={(event) => {
                    event.stopPropagation()
                    changeHandler(ele)
                  }}
                  className="dropdown-menu-item"
                  style={getDropdownItemsStyles({
                    isSelectedIndex: canHighlightSelectedIndex
                  })}
                  key={index}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {ele}
                    {canHighlightSelectedIndex && <Icon name="blue-tick" width={12} />}
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </>
  )
}

Dropdown.defaultProps = {
  selectedLanguage: APP_CONSTANTS.DEFAULT_LANGUAGE,
  type: DROPDOWN_TYPES.BUTTON,
  labelId: ''
}

export default Dropdown
