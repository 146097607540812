import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useFetch from '../../Components/useFetch/useFetch'
import { USER_SESSION_DOES_NOT_EXIST } from '../../Components/UserSession/UserSession'
import {
  getValidatedAuthMethods,
  responseDataInterceptorAuthentications
} from '../../utils/auth-methods'
import { orgConfigInterceptor } from '../../utils/orgUtils'
import { delve, getRedirectUriFromError } from '../../utils/utility'
import AppDataProviderContext from './AppDataProviderContext'
import ErrorBoundary from './ErrorBoundary'

const getParamsForAuthentications = (search) => {
  let allUrlQueryParams = {}
  for (let [key, value] of new URLSearchParams(search).entries()) {
    allUrlQueryParams[key] = value
  }
  return allUrlQueryParams
}

const isDomainUpdated = (error) =>
  error.status === 400 && delve(error, 'data.error', '') === 'ORGANISATION_DOMAIN_UPDATED'

const AppDataProviderState = (props) => {
  const { search } = useLocation()
  const [responsesData, setResponsesData] = React.useState({})
  const [refreshCache, setRefreshCache] = React.useState({
    getAllowedLoginMethods: {}
  })

  useFetch({
    requestName: 'getCurrentUserDetails',
    onSuccessHandler: (response) => {
      setResponsesData((prev) => ({ ...prev, getCurrentUserDetails: response.data }))
    },
    onErrorHandler: (error) => {
      setResponsesData((prev) => ({ ...prev, getCurrentUserDetails: USER_SESSION_DOES_NOT_EXIST }))
      // if org domain is updated and user accesses old domain, catch the
      // backend error response and check if response is `HTTP 400` & error
      // reads `ORGANISATION_DOMAIN_UPDATED` then redirect implicitly to new domain
      if (isDomainUpdated(error)) {
        window.location.assign(getRedirectUriFromError(error))
        return
      }
      if (window.Sentry && window.Sentry.configureScope) {
        window.Sentry.configureScope((scope) => {
          scope.setTag('Heap User ID', window.heap && window.heap.userId)
        })
      }
    }
  })

  useFetch({
    requestName: 'getOrgConfig',
    onSuccessHandler: (response) => {
      setResponsesData((prev) => ({ ...prev, getOrgConfig: response.data }))
    },
    transformResponseData: orgConfigInterceptor
  })

  const getAllowedLoginMethods = useFetch({
    requestName: 'getAllowedLoginMethods',
    requestParams: getParamsForAuthentications(search),
    transformResponseData: responseDataInterceptorAuthentications,
    onSuccessHandler: (response) => {
      const authModuleConfig = getValidatedAuthMethods(response.data)
      setResponsesData((prev) => ({ ...prev, getAllowedLoginMethods: authModuleConfig }))
    }
  })

  const getDataFromAppStateContext = (patterns, defaultValues) => {
    if (typeof patterns === 'string') {
      return delve(responsesData, patterns, defaultValues)
    } else if (Array.isArray(patterns)) {
      defaultValues = !Array.isArray(defaultValues) ? [] : defaultValues
      return patterns.map((pattern, index) =>
        getDataFromAppStateContext(pattern, delve(defaultValues, `${index}`))
      )
    }
  }

  const isDataReady = Object.keys(responsesData).length === 3

  useEffect(() => {
    if (getAllowedLoginMethods.data) {
      setRefreshCache((prevCache) => ({
        ...prevCache,
        getAllowedLoginMethods: getAllowedLoginMethods
      }))
    }
  }, [getAllowedLoginMethods.data])

  return (
    <AppDataProviderContext.Provider
      value={{ getDataFromAppStateContext, refreshCache, setRefreshCache }}>
      {isDataReady ? props.children : `Loading...`}
    </AppDataProviderContext.Provider>
  )
}

const AppDataProviderStateWrapper = ({ children, renderWithoutContext }) => {
  return (
    <ErrorBoundary>
      {renderWithoutContext ? children : <AppDataProviderState>{children}</AppDataProviderState>}
    </ErrorBoundary>
  )
}

export default AppDataProviderStateWrapper
