import React, { createContext, useState, useContext, Fragment } from 'react'

import Transition from 'react-transition-group/Transition'

import Icon from '../Icon'

import colors from '../../themes/freshworks/colors'
import { APP_CONSTANTS } from '../../constants'
import { PrimaryText } from '../../typography'

const TOAST_WIDTH = (window && window.innerWidth) < APP_CONSTANTS.SCREEN_SIZES.md ? 250 : 410
const STACK_WIDTH = TOAST_WIDTH - 20
const Z_INDEX = 9999

const getToastContainerStyles = ({ toastIndex, isToastVisible }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  zIndex: Z_INDEX - toastIndex,
  background: colors.white,
  boxShadow: `0px 2px 4px ${colors.border.primary}`,
  border: `1px solid ${colors.border.secondary}`,
  borderRadius: '4px',
  overflow: 'hidden',
  top: '10px',
  visibility: isToastVisible ? 'visibile' : 'hidden'
})

const toastTypeRuleStyles = (level) => ({
  width: '100%',
  height: '4px',
  background: colors.components.toast[level] || TOAST_LEVELS.LOADING,
  borderRadius: '4px 4px 0px 0px'
})

const closeButtonStyles = {
  position: 'absolute',
  cursor: 'pointer',
  top: '12px',
  right: '12px'
}

const toastStackContainerStyles = {
  display: 'flex',
  position: 'fixed',
  top: 0,
  left: '50%',
  zIndex: Z_INDEX,
  transform: 'translateX(-50%)',
  width: `${TOAST_WIDTH - 10}px`,
  flexDirection: 'column'
}

const stackBoxStyles = {
  background: colors.white,
  boxShadow: `0px 2px 4px ${colors.border.primary}`,
  border: `1px solid ${colors.border.secondary}`,
  overflow: 'hidden',
  zIndex: Z_INDEX - 2,
  width: `${STACK_WIDTH}px`,
  height: '8px',
  margin: 'auto',
  borderRadius: '0 0 4px 4px'
}

const toastContentBoxStyles = {
  width: '100%',
  paddingLeft: '16px',
  wordWrap: 'break-word'
}

export const TOAST_LEVELS = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARN: 'warn',
  LOADING: 'loading'
}

export const ERROR_TOAST_TIMEOUT = 10000000

const SlideTransition = ({ in: inProp, children, index, canTransform }) => {
  const defaultStyle = (index) => ({
    transition: `transform 200ms ease-out`,
    transform: `translateY(${index * -100}%)`,
    height: '100%',
    zIndex: `${Z_INDEX - index}`
  })

  const transitionStyles = (state, index) => {
    const TRANSITION_STATES = {
      ENTERING: 'entering',
      ENTERED: 'entered',
      EXITED: 'exited'
    }
    let transformStyleObj = {}
    if (state === TRANSITION_STATES.ENTERING) {
      transformStyleObj = { transform: `translateY(${index * -100}%)` }
    } else if (state === TRANSITION_STATES.ENTERED) {
      transformStyleObj = { transform: `translateY(${(index - 1) * 8}px)` }
    } else if (state === TRANSITION_STATES.EXITED) {
      transformStyleObj = { opacity: 0, transform: `translateY(-100%)` }
    }
    return transformStyleObj
  }

  const transformStyle = (canTransform, index) =>
    canTransform
      ? {}
      : {
          transition: `opacity 200ms ease-out, transform 200ms ease-out`,
          transform: `translateY(${index * -100 + 100}%)`,
          height: '100%',
          width: `${TOAST_WIDTH - index * 10}px`,
          margin: 'auto'
        }
  return (
    <Transition in={inProp} timeout={200} unmountOnExit>
      {(state) => (
        <div
          style={{
            ...defaultStyle(index),
            ...transitionStyles(state, index),
            ...transformStyle(canTransform, index)
          }}>
          {children}
        </div>
      )}
    </Transition>
  )
}

const ToastContent = ({ content, config: { level }, toastId, setCanTransform, closeToast }) => {
  const toastIconMap = {
    [TOAST_LEVELS.SUCCESS]: 'toast-success',
    [TOAST_LEVELS.ERROR]: 'toast-error',
    [TOAST_LEVELS.WARN]: 'toast-warn',
    [TOAST_LEVELS.LOADING]: 'toast-loading'
  }
  const onToastClose = () => {
    closeToast(toastId)
    setCanTransform(false)
  }
  return (
    <Fragment>
      <div style={toastTypeRuleStyles(level)} />
      <div
        style={{ padding: '16px', paddingRight: '40px' }}
        onClick={() => setCanTransform((canTransform) => !canTransform)}>
        <div style={{ display: 'flex' }}>
          <div style={{ paddingTop: '4px' }} data-testid={`toast-level-${level}`}>
            <Icon name={toastIconMap[level] || toastIconMap[TOAST_LEVELS.LOADING]} width="16px" />
          </div>
          <div style={toastContentBoxStyles}>
            <PrimaryText textWeight="semiBold" role="alert">
              {content}
            </PrimaryText>
          </div>
        </div>
      </div>
      <div
        style={closeButtonStyles}
        data-testid="close-toast"
        tabIndex="0"
        role="button"
        onClick={() => {
          onToastClose()
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onToastClose()
          }
        }}>
        <Icon name="close-bold" width="10px" />
      </div>
    </Fragment>
  )
}

const Toast = ({ content, config, index, toastId, canTransform, closeToast, setCanTransform }) => {
  const [showWithTransition, setShowWithTransition] = useState(false)
  const [stackState, setStackState] = useState(canTransform)
  if (canTransform) {
    // added timeout for async execution - for transition effect purpose
    setTimeout(() => setShowWithTransition(true))
    setTimeout(() => setStackState(canTransform), 150)
  } else {
    // added timeout for async execution - for transition effect purpose
    setTimeout(() => setStackState(canTransform))
    setTimeout(() => setShowWithTransition(true), 100)
  }
  return (
    <SlideTransition in={showWithTransition} index={index + 1} canTransform={!canTransform}>
      <div
        className="toast-container"
        tabIndex="0"
        style={getToastContainerStyles({
          isToastVisible: index === 0 || !stackState,
          toastIndex: index + 1
        })}>
        <ToastContent
          content={content}
          config={config}
          toastId={toastId}
          setCanTransform={setCanTransform}
          closeToast={closeToast}
        />
      </div>
      {index === 0 && canTransform && <div style={stackBoxStyles} data-testid="toast-stack-box" />}
    </SlideTransition>
  )
}

const Context = createContext([])
const ToastContext = ({ children }) => {
  const DEFAULT_TIMEOUT = 5000
  const [toastStates, setToastStates] = useState([])
  const [canTransform, setCanTransform] = useState(false)
  const [timeOutQueue, setTimeOutQueue] = useState({})

  const DEFAULT_CONFIG = { level: 'loading', timeout: ERROR_TOAST_TIMEOUT, autoClose: true }

  const showToast = (content, config) => {
    config = { ...DEFAULT_CONFIG, ...config }
    setCanTransform(false)

    // generating random incremental toast id
    const toastId = new Date().getTime()
    const toastObj = { content, ...config, hasExpired: false }
    setToastStates((toastStates) => ({ ...toastStates, [toastId]: toastObj }))

    if (!!config.autoClose) {
      // set timeout to enable auto close
      const timeoutId = setTimeout(
        (toastId) => {
          setToastStates((toastStates) => {
            const { [toastId]: toastToExpire, ...restOfTheToasts } = toastStates
            toastToExpire.hasExpired = true
            return { [toastId]: toastToExpire, ...restOfTheToasts }
          })
          setCanTransform(false)
          // remove toast reference from timeout queue on auto close
          setTimeOutQueue((timeOutQueue) => {
            const { [toastId]: toastToExpire, ...rest } = timeOutQueue
            return rest
          })
        },
        config.timeout || DEFAULT_TIMEOUT,
        toastId
      )

      setTimeOutQueue((timeOutQueue) => ({
        ...timeOutQueue,
        [toastId]: timeoutId
      }))
    }
  }

  const closeToast = (toastId) => {
    if (toastStates.length === 2) {
      setCanTransform(false)
    }
    setToastStates((toastStates) => {
      const { [toastId]: toastToExpire, ...restOfTheToasts } = toastStates
      toastToExpire.hasExpired = true
      return { [toastId]: toastToExpire, ...restOfTheToasts }
    })
    // remove toast reference from timeout queue on toast close
    setTimeOutQueue((timeOutQueue) => {
      const { [toastId]: toastToExpire, ...rest } = timeOutQueue
      return rest
    })

    if (timeOutQueue.length > 0) {
      clearTimeout(timeOutQueue[toastId])
    }
  }

  let unexpiredIndexCount = -1
  return (
    <Context.Provider value={showToast}>
      <Fragment>
        <div style={toastStackContainerStyles}>
          {Object.keys(toastStates).map((toastId, index) => {
            const { content, ...config } = toastStates[toastId]
            !config.hasExpired && unexpiredIndexCount++
            return (
              !config.hasExpired && (
                <Toast
                  key={index}
                  content={content}
                  config={config}
                  index={unexpiredIndexCount}
                  toastId={toastId}
                  canTransform={canTransform}
                  closeToast={closeToast}
                  setCanTransform={(canTransform) => {
                    setCanTransform(Object.keys(toastStates).length > 1 && canTransform)
                  }}
                />
              )
            )
          })}
        </div>
        {children}
      </Fragment>
    </Context.Provider>
  )
}

const useToast = () => {
  const showToast = useContext(Context)
  return showToast
}

export default useToast
export { ToastContext }
