import ENV from '../../env.config.json'
export const LoginRequestHeadersConfig = {
  // Currently these routes are involved in login flow
  // TODO : For now we are maintaining this soon this will be moved to
  // Independent Login Page app as mentioned in this task FRESHID-88188
  forRoutes: ['login', 'login-consent', 'magiclink', 'forgot-password', 'mfa'],
  headers: {
    'x-request-target': 'login'
  }
}

// For now I have defaulted this function with "LoginRequestHeadersConfig" as the login headers are the only route based header
// need for now later if more activities need different headers to be sent we can revisit the default parameter later by
// creating default function for each type of headers. These can also be extended to do something else also. This can be scaled
// till we can modify the entire config by passing a custom function into the axios interceptor which can take and return the config
export const getPathNameBasedHeadersValue = (
  currentFullPathName = window.location.pathname || '',
  requestHeadersConfig = LoginRequestHeadersConfig
) => {
  const currentRouteName = currentFullPathName.split('/')
  if (
    requestHeadersConfig.forRoutes.some((routeName) => currentRouteName.indexOf(routeName) !== -1)
  ) {
    return requestHeadersConfig.headers
  }
  return {}
}

const requestsConfig = {
  getCurrentUserDetails: {
    method: 'GET',
    url: '/users/current'
  },
  getOrgConfig: {
    method: 'GET',
    url: '/organisations/-/appInitPayload',
    headers: getPathNameBasedHeadersValue()
  },
  getAllowedLoginMethods: {
    method: 'GET',
    url: (shouldQueryMfa = false, hash = false) => {
      let queryParams = {
        fetch_mfa_configuration: shouldQueryMfa,
        hash_code: hash
      }
      let queryString = ''

      for (const [key, value] of Object.entries(queryParams)) {
        if (value) {
          queryString += `&${key}=${value}`
        }
      }
      if (queryString) {
        queryString = `?${queryString.slice(1)}`
      }
      return `/authentications${queryString}`
    },
    headers: getPathNameBasedHeadersValue()
  },
  loginViaEmail: {
    method: 'POST',
    url: '/login'
  },
  checkActivationHash: {
    method: 'GET',
    url: (activationHash) => `/users/-/hashes/${activationHash}/activation`
  },
  getUserDetailsFromHash: {
    method: 'GET',
    url: (activationHash) => `/users/-/hashes/${activationHash}/details`
  },
  activateUser: {
    method: 'PATCH',
    url: (activationHash) => `/users/-/hashes/${activationHash}/activate`
  },
  requestMagicLink: {
    method: 'POST',
    url: '/magiclink/email/send'
  },
  loginWithMagicLinkHash: {
    method: 'POST',
    url: (hash) => `/magiclink/${hash}/login`
  },
  requestActivationEmail: {
    method: 'PATCH',
    url: (email) => `/users/${email}/resend`
  },
  requestResetPasswordLink: {
    method: 'POST',
    url: '/users/reset-password'
  },
  getResetHashDetails: {
    method: 'GET',
    url: (passwordHash) => `/users/-/hashes/${passwordHash}/reset-password`
  },
  resetUserPassword: {
    method: 'PATCH',
    url: (passwordHash) => `/users/-/hashes/${passwordHash}/reset-password`
  },
  checkPasswordHash: {
    method: 'GET',
    url: (passwordHash) => `/users/-/hashes/${passwordHash}/set-password`
  },
  setUserPassword: {
    method: 'PATCH',
    url: (passwordHash) => `/users/-/hashes/${passwordHash}/set-password`
  },
  updateUserPassword: {
    method: 'PUT',
    url: 'user/reset-password'
  },
  enrollMfa: {
    method: 'POST',
    url: '/organisations/-/users/-/mfa/enroll'
  },
  loginMfaActivate: {
    method: 'POST',
    url: (mfaId) => `/login/mfa/${mfaId}/activate`
  },
  requestMfaRecoveryCode: {
    method: 'POST',
    url: '/organisations/-/users/-/mfa/recovery/request'
  },
  loginMfa: {
    method: 'POST',
    url: '/login/mfa'
  },
  signIn: {
    method: 'POST',
    /*This activity has no predefined url, FE gets the url as a response from another API response,
    hence returning the same url which is passed in pathFragment of DataManager. */
    url: (url) => url
  },
  requestGlobalUserSearch: {
    method: 'POST',
    /*This activity has no predefined url, FE gets the domain as env config and construct the absolute url,
    hence returning the same url which is passed in pathFragment. */
    url: (host) => `https://${host}/org/api/v2/global/users/search`
  },
  unlockUser: {
    method: 'PATCH',
    url: (unlockHash) => `/users/-/hashes/${unlockHash}/unlock`
  },
  checkIfOrgDomainExists: {
    method: 'GET',
    url: ((protocol, baseUrl, namespace, orgDomain) =>
      `${protocol}//${baseUrl}/${namespace}/organisations/domain/${orgDomain}/available`).bind(
      this,
      window.location.protocol,
      process.env.REACT_APP_FID_GLOBAL_HOST,
      ENV.NAMESPACE
    )
  },
  resolveLoginHint: {
    method: 'POST',
    url: '/login_hint/resolve'
  },
  resendOTP: {
    method: 'POST',
    url: '/login/mfa/resend'
  },
  getErrorDescription: {
    method: 'GET',
    url: (errorDescriptionId) => `/errors/${errorDescriptionId}`
  },
  getUserMfaStatus: {
    method: 'GET',
    url: (userId) => `/users/${userId}/mfa/status`
  }
}

export default requestsConfig
