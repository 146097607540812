import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { useEffect, useState } from 'react'
import {
  camelizeKeysInObj,
  decamelizeKeys,
  getErrorList,
  getUrlPrefix,
  heapTrack
} from '../../utils/utility'
import requestsConfig from './requests-config'
import ENV from '../../env.config.json'
import { APP_CONSTANTS } from '../../constants'

const {
  NODE_ENV: { DEV, TEST }
} = APP_CONSTANTS

const initialBaseURL =
  process.env.NODE_ENV === DEV || process.env.NODE_ENV === TEST ? '' : `${ENV.HOST}${ENV.NAMESPACE}`

const getBaseURL = (defaultBaseURL) => `${getUrlPrefix('')}${defaultBaseURL}`

export const applyMock = () => new MockAdapter(axios, { delayResponse: 300 })

const getDefaultErrorResponse = () => ({
  error: APP_CONSTANTS.ERROR_STRINGS.UNEXPECTED_RESPONSE,
  message: APP_CONSTANTS.ERROR_STRINGS.UNEXPECTED_RESPONSE,
  details: [
    {
      '@type': 'type.googleapis.com/google.rpc.BadRequest',
      field_violations: [
        {
          field: APP_CONSTANTS.ERROR_STRINGS.UNEXPECTED_RESPONSE,
          description: APP_CONSTANTS.ERROR_STRINGS.UNEXPECTED_RESPONSE
        }
      ]
    }
  ]
})

const useFetch = ({
  requestName,
  lazy = false,
  requestData = {},
  requestParams = {},
  onSuccessHandler = () => {},
  onErrorHandler = () => {},
  pathFragments = [],
  transformResponseData = (arg) => arg
}) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  const makeRequest = (requestData) => {
    setLoading(true)

    axios.interceptors.response.use((res) => {
      res.data = camelizeKeysInObj(res.data)
      res.data = transformResponseData(res.data)
      return res
    })

    axios.interceptors.request.use((config) => {
      if (config.data) {
        config.data = decamelizeKeys(config.data)
      }
      return config
    })

    axios({
      baseURL: getBaseURL(initialBaseURL),
      withCredentials: true,
      ...requestsConfig[requestName],
      ...(typeof requestsConfig[requestName].url === 'function' && {
        url: requestsConfig[requestName].url(...pathFragments)
      }),
      ...(requestData && { data: requestData }),
      ...(requestParams && { params: requestParams })
    })
      .then((res) => {
        heapTrack(`API:${requestName}Success`)
        onSuccessHandler(res)
        setData(res.data)
      })
      .catch((error) => {
        heapTrack(`API:${requestName}Failure`)

        if (error.response && typeof error.response.data === 'object') {
          error.response.data.error = error.response.data.error
            ? error.response.data.error.toUpperCase()
            : ''
        }

        const errorResponse = error.response || getDefaultErrorResponse()

        if (!errorResponse.config && error.config) {
          errorResponse.config = error.config
        }
        setError(errorResponse)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!lazy) {
      makeRequest(requestData)
    }
  }, []) // Empty dependency array ensures this runs once on mount

  useEffect(() => {
    if (!!error) {
      onErrorHandler({
        ...error,
        ...getErrorList(error && error.data)
      })
    }
  }, [error])

  return { makeRequest, loading, data, error }
}

export default useFetch
