import React, { useCallback, useContext, useEffect, useState } from 'react'
import IntlContext from './IntlContext'
import translation from '../../default_translations/en-us.json'
import { delve } from '../../utils/utility'
import { REACT_APP_CDN_URL } from '../../config'
import { APP_CONSTANTS } from '../../constants'
import { isRTLLanguage, setUserLanguageToLocalStorage, updateDocumentDir } from './langUtils'

export const LOCALE_FETCH_ERRORS = {
  PREVIOUS_REQUEST_IN_PROGRESS: 'PREVIOUS_REQUEST_IN_PROGRESS',
  NETWORK_ERROR: 'NETWORK_ERROR'
}

const setToLocalStorageAndUpdateRTL = (locale) => {
  // every time update the locale to local storage
  setUserLanguageToLocalStorage(locale)

  // update if the language is RTL language
  const isRTL = isRTLLanguage(locale)
  updateDocumentDir(isRTL)
}

let getTranslationFileLocation = (userLang = '') => {
  // this is because when the CDN URL is '/'
  // it takes the relative path like /user/{id}/profile/translation/json/file
  // hence having this as absolute urls
  const url =
    REACT_APP_CDN_URL === '/' ? `${APP_CONSTANTS.DEFAULT_REDIRECT_PATH}/` : REACT_APP_CDN_URL
  return `${url}${APP_CONSTANTS.TRANSLATION_LOCATION}/${userLang}.json`
}

const getErrorObj = (errorType, reason = '') => {
  return {
    errorType,
    reason
  }
}

// On load of the app for first time, AppDataProvider does setLocale twice.
// This is because even before the first XHR completes, the re-render happens in RDTSA and
// second XHR is fired, so having this feature of processing one request at a time

// flag for not firing multiple fetch calls from LanguageProvider
let isRequestProcessing = false

const localeFetch = (locale) => {
  const userTranslationFile = getTranslationFileLocation(locale)
  // reject the request if another request is in progress
  if (isRequestProcessing) {
    return new Promise((resolve, reject) => {
      reject(getErrorObj(LOCALE_FETCH_ERRORS.PREVIOUS_REQUEST_IN_PROGRESS, ''))
    })
  }

  return new Promise((resolve, reject) => {
    isRequestProcessing = true
    Promise.all([fetch(userTranslationFile).then((resp) => resp.json())])
      .then(([userLangTranslation]) => {
        // Depending on the configuration (webpack, babel, etc.),
        // when importing dynamically, the received module data
        // can be nested into a default property.
        resolve(userLangTranslation)
      })
      .catch((error) => {
        reject(
          getErrorObj(
            LOCALE_FETCH_ERRORS.NETWORK_ERROR,
            `localFetch.js cannot request language files. Reason -> ${error}`
          )
        )
      })
      .finally(() => {
        isRequestProcessing = false
      })
  })
}

const IntlState = ({ children }) => {
  const [locale, setLocale] = useState('en-US')
  const [translationObject, setTranslationObject] = useState({ 'en-US': translation })

  const toIntlString = useCallback(
    (translationPath, obj = {}) => {
      let message = translationPath
      if (translationObject[locale]) {
        message = delve(translationObject[locale], translationPath, translationPath)
      } else {
        message = delve(translation, translationPath, translationPath)
      }

      Object.keys(obj).forEach((key) => {
        message = message.replace(`{${key}}`, obj[key])
      })
      return message
    },
    [translationObject, locale]
  )

  useEffect(() => {
    if (!translationObject[locale]) {
      localeFetch(locale)
        .then((userTranslation) => {
          setTranslationObject((translationObject) => ({
            ...translationObject,
            [locale]: userTranslation
          }))
          setToLocalStorageAndUpdateRTL(locale)
        })
        .catch((error) => {
          // localeFetch rejects if multiple requests are fired too!
          // so explicitly checking for `NETWORK_ERROR`
          if (error.errorType === LOCALE_FETCH_ERRORS.NETWORK_ERROR) {
            window.Sentry && window.Sentry.captureException(new Error(error.reason))
            // addLocaleData(en)
            setToLocalStorageAndUpdateRTL('en-US')
            setLocale('en-US')
          }
        })
    } else {
      setToLocalStorageAndUpdateRTL(locale)
    }
  }, [locale])

  return (
    <IntlContext.Provider value={{ toIntlString, setLocale, locale }}>
      {children}
    </IntlContext.Provider>
  )
}

export const useIntl = () => useContext(IntlContext)
export default IntlState
