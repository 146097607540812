import React, { Fragment } from 'react'
import useToast, { TOAST_LEVELS, ERROR_TOAST_TIMEOUT } from '../../Components/Toast'
import { useNavigate } from 'react-router-dom'
import { APP_CONSTANTS } from '../../constants'
import { useIntl } from '../../Contexts/IntlContext/IntlState'
import { H1, PrimaryText } from '../../typography'
import { OneColumnContentBox, OneColumnContentSection } from '../OneColumnContentBox'
import UserSession from '../UserSession/UserSession'
import Icon from '../Icon'
import LinkBetweenText from '../LinkBetweenText/LinkBetweenText'

const containerStyles = { textAlign: 'center', display: 'flex', flexDirection: 'column' }

export const NotFoundContent = React.memo(() => {
  const homeUrl = `${APP_CONSTANTS.DEFAULT_REDIRECT_PATH}${APP_CONSTANTS.LOGIN_PATHNAME}`
  return (
    <OneColumnContentBox>
      {/* TODO :: Conditionally show Custom Logo or Org Logo */}
      <OneColumnContentSection CustomLogoName="freshworks_title_logo" disableFormFocus>
        <div style={containerStyles}>
          <div style={{ marginBottom: '8px' }}>
            <H1>404!</H1>
          </div>
          <div style={{ width: '75%', margin: 'auto', marginBottom: '16px' }}>
            <Icon name="404" extension="png" />
          </div>
          <div style={{ marginTop: '16px', marginBottom: '32px' }}>
            <PrimaryText>
              <LinkBetweenText
                translationKey={'error.page_not_found'}
                placeHolderReplacementObject={{
                  home: {
                    textKey: 'error.home',
                    link: homeUrl,
                    target: '_blank'
                  }
                }}
              />
            </PrimaryText>
          </div>
        </div>
      </OneColumnContentSection>
    </OneColumnContentBox>
  )
})

const PageNotFound = React.memo(() => {
  const navigate = useNavigate()
  const toast = useToast()
  const { toIntlString } = useIntl()
  return (
    <Fragment>
      <UserSession.IfUserSessionExists>
        {() => {
          toast(toIntlString(`page_not_found.toast_message`), {
            level: TOAST_LEVELS.ERROR,
            timeout: ERROR_TOAST_TIMEOUT
          })
          window.location.href = APP_CONSTANTS.DEFAULT_REDIRECT_PAGE
          return null
        }}
      </UserSession.IfUserSessionExists>
      <UserSession.IfUserSessionAbsent>
        <NotFoundContent />
      </UserSession.IfUserSessionAbsent>
    </Fragment>
  )
})

export default PageNotFound
