import React from 'react'
import Logo from '../../Components/Logo'
import { useIntl } from '../IntlContext/IntlState'
import { TOP_MARGIN } from '../../Components/OneColumnContentBox/OneColumnContentBox'
import LinkBetweenText from '../../Components/LinkBetweenText/LinkBetweenText'
import Icon from '../../Components/Icon'
import Fade from '../../Components/Transitions/Fade'
import { H3, PrimaryText } from '../../typography'
import Loader from '../../Components/Loader'
import { AUTH_CONST } from '../../utils/auth-methods'

const TEXT_ALIGN = { textAlign: 'center' }
const {
  AUTHENTICATIONS_METHOD_FAILED,
  NO_AUTHENTICATION_METHODS,
  NO_AUTH_OPTIONS_CONFIGURED,
  ORG_DOMAIN_BEING_SETUP
} = AUTH_CONST

const getFlexStyles = (
  direction = 'row',
  justifyContent = 'flex-start',
  alignItems = 'stretch'
) => ({
  display: 'flex',
  flexDirection: direction,
  justifyContent,
  alignItems
})
const AuthenticationsFallback = React.memo(({ type, orgUrl = null }) => {
  const { toIntlString } = useIntl()
  return (
    <div style={{ ...getFlexStyles('column'), width: '100%', marginTop: TOP_MARGIN }}>
      <div style={{ ...TEXT_ALIGN, marginBottom: '32px', width: '100%' }}>
        {(type !== ORG_DOMAIN_BEING_SETUP && <Logo isIcon size="large" />) || (
          <Icon altText="Freshworks" name={'freshworks_title_logo'} style={{ width: '210px' }} />
        )}
      </div>

      <Fade in={true}>
        <div style={{ ...TEXT_ALIGN, margin: 'auto', marginTop: '128px', width: '100%' }}>
          {type === ORG_DOMAIN_BEING_SETUP && (
            <div
              style={{
                ...getFlexStyles('row', 'center', 'center'),
                margin: 'auto',
                marginBottom: '30px'
              }}>
              <Loader />
            </div>
          )}
          <div>
            <H3>
              {type === AUTHENTICATIONS_METHOD_FAILED &&
                toIntlString('error.auth_methods_failed.title')}
              {type === NO_AUTHENTICATION_METHODS && toIntlString('error.no_auth_methods.title')}
              {type === NO_AUTH_OPTIONS_CONFIGURED &&
                toIntlString('error.no_auth_options_configured.title')}
              {type === ORG_DOMAIN_BEING_SETUP && (
                <LinkBetweenText
                  style={{ fontSize: '24px' }}
                  translationKey={'error.org_domain_being_set_up.title'}
                  placeHolderReplacementObject={{
                    org_domain: {
                      textKey: orgUrl,
                      link: `${window.location.protocol}//${orgUrl}`
                    }
                  }}
                />
              )}
            </H3>
          </div>
          <div mt={4} width={1}>
            <PrimaryText>
              {type === AUTHENTICATIONS_METHOD_FAILED && (
                <LinkBetweenText
                  style={{ fontSize: '24px' }}
                  translationKey={'error.auth_methods_failed.desc'}
                  placeHolderReplacementObject={{
                    support_email: {
                      textKey: 'error.support_email',
                      link: 'mailTo:support@freshworks.com'
                    }
                  }}
                />
              )}

              {type === NO_AUTHENTICATION_METHODS && toIntlString('error.no_auth_methods.desc')}

              {type === ORG_DOMAIN_BEING_SETUP && (
                <div style={{ marginTop: '32px' }}>
                  <LinkBetweenText
                    style={{ fontSize: '14px' }}
                    translationKey={'error.org_domain_being_set_up.desc'}
                    placeHolderReplacementObject={{
                      support_email: {
                        textKey: 'error.support_email',
                        link: 'mailTo:support@freshworks.com'
                      }
                    }}
                  />
                </div>
              )}
            </PrimaryText>
          </div>
        </div>
      </Fade>
    </div>
  )
})

export default AuthenticationsFallback
