import palette from './palette'
import colors from './colors'
import typography from './typography'
//import { CONST } from '../../../utils'

// rebass uses min-width for breakpoints for responsiveness
// <Flex flexDirection={[ 'column', 'row' ]} />
// flexDirection is column until min-width of the device is 700px
// flexDirection is row at after 700px
//const breakpoints = Object.values(CONST.SCREEN_SIZES)

export default { palette, typography, colors }
