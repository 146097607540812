import { APP_CONSTANTS } from '../../constants'
import isStorageAvailable from './isStorageAvailable'

const isRTLLanguage = (langCode) => APP_CONSTANTS.RTL_LANGUAGES_LCID.indexOf(langCode) > -1
const STR_DIR_ATTRIBUTE = 'dir'
const STR_DIR_LTR_VAL = 'ltr'
const STR_DIR_RTL_VAL = 'rtl'
const POSITION_LEFT_STR = 'left'
const POSITION_RIGHT_STR = 'right'

const changeToRTLStrings = (str) => {
  return str.replace(
    new RegExp(`(${POSITION_LEFT_STR}|${POSITION_RIGHT_STR})`, 'ig'),
    function (match) {
      return match === POSITION_LEFT_STR ? POSITION_RIGHT_STR : POSITION_LEFT_STR
    }
  )
}

const getUserLanguageFromLocalStorageOrFallbackToDefault = () => {
  // gets the current language of user from local storage or falls back to default lang (english)
  return getUserLanguageFromLocalStorage() || APP_CONSTANTS.DEFAULT_LANGUAGE
}

const getUserLanguageFromLocalStorage = () => {
  if (isStorageAvailable(APP_CONSTANTS.WEB_STORAGE.LOCAL_STORAGE)) {
    return localStorage.getItem(APP_CONSTANTS.USER_LANGUAGE_PATH)
  }
  return null
}

const setUserLanguageToLocalStorage = (language) => {
  if (isStorageAvailable(APP_CONSTANTS.WEB_STORAGE.LOCAL_STORAGE)) {
    localStorage.setItem(APP_CONSTANTS.USER_LANGUAGE_PATH, language)
  }
}

const updateDocumentDir = (isRTL) => {
  if (isRTL === undefined) {
    isRTL = isRTLLanguage(getUserLanguageFromLocalStorageOrFallbackToDefault())
  }
  document.body.setAttribute(STR_DIR_ATTRIBUTE, isRTL ? STR_DIR_RTL_VAL : STR_DIR_LTR_VAL)
}

export {
  isRTLLanguage,
  updateDocumentDir,
  getUserLanguageFromLocalStorageOrFallbackToDefault,
  getUserLanguageFromLocalStorage,
  setUserLanguageToLocalStorage,
  changeToRTLStrings
}
