import { memo, Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import { OneColumnContentBox } from '../../Components/OneColumnContentBox'
import UserSession from '../../Components/UserSession/UserSession'
import { APP_CONSTANTS } from '../../constants'
import { callOauthEndpoint, camelizeKeysInObj, getQueryParams } from '../../utils/utility'
import Loader from '../../Components/Loader'
import { FRESHID_CLIENT_ID } from '../../config'

export const LOGIN_PAGE_PARAM_KEYS = {
  CLIENT_ID: 'client_id',
  REDIRECT_URI: 'redirect_uri',
  AUTH: 'auth',
  UNIFIED_URL: 'uf',
  PRODUCT_REDIRECT_URI: 'product_redirect_uri'
}

export const getRequiredQueryParams = (isUnifiedUrl) => {
  const requiredParams = [LOGIN_PAGE_PARAM_KEYS.REDIRECT_URI]
  // client_id is not a required query param in case of unified url
  if (!isUnifiedUrl) {
    requiredParams.push(LOGIN_PAGE_PARAM_KEYS.CLIENT_ID)
  }
  return requiredParams
}

export const getDefaultQueryParams = (isUnifiedUrl) => {
  const params = {
    [LOGIN_PAGE_PARAM_KEYS.REDIRECT_URI]: APP_CONSTANTS.DEFAULT_REDIRECT_URL,
    [LOGIN_PAGE_PARAM_KEYS.AUTH]: 'no'
  }
  // client_id is not a default query param in case of unified url
  if (!isUnifiedUrl) {
    params[LOGIN_PAGE_PARAM_KEYS.CLIENT_ID] = FRESHID_CLIENT_ID
  }
  return params
}

export const addQueryParams = (queryParamsString, newParamEntries) => {
  let uRLSearchParams = new URLSearchParams(queryParamsString)

  Object.keys(newParamEntries).forEach((key) => {
    !uRLSearchParams.has(key) && uRLSearchParams.append(key, newParamEntries[key])
  })

  return uRLSearchParams.toString()
}

export const RedirectIfSessionExists = ({ redirectUri }) => {
  const { search = '' } = useLocation()
  const clientId = getQueryParams(LOGIN_PAGE_PARAM_KEYS.CLIENT_ID, search)
  if (clientId) {
    //absolute url's which are not unified url's
    //redirect to oauth authorize from which if authenticated will go to the redirect url given.
    callOauthEndpoint(search)
  } else {
    //unified url with absolute path
    window.location.assign(redirectUri)
  }

  return null
}

export const RenderOnSession = memo(({ children, forceRender }) => {
  if (forceRender) {
    // used for use cases of force display pre-login screens
    // even though the session is present in the browser window
    return children
  } else {
    return (
      <Fragment>
        <UserSession.IfUserSessionExists>
          <RedirectIfSessionExists
            redirectUri={
              getQueryParams(APP_CONSTANTS.REDIRECT_URI) || APP_CONSTANTS.DEFAULT_REDIRECT_URL
            }
          />
          <OneColumnContentBox>
            <Loader isLoading={true} />
          </OneColumnContentBox>
        </UserSession.IfUserSessionExists>
        <UserSession.IfUserSessionAbsent>{children}</UserSession.IfUserSessionAbsent>
      </Fragment>
    )
  }
})
