import React from 'react'
import AuthenticationsFallback from '../AppDataProvider/AuthenticationsFallback'
import OrgSetupInProgressMessage from './OrgSetupInProgressMessage'
import { AUTH_CONST } from '../../utils/auth-methods'
import { APP_CONSTANTS } from '../../constants'

const errorTypesToBeHandled = [
  AUTH_CONST.AUTHENTICATIONS_METHOD_FAILED,
  AUTH_CONST.NO_AUTHENTICATION_METHODS,
  AUTH_CONST.NO_AUTH_OPTIONS_CONFIGURED,
  AUTH_CONST.ORG_DOMAIN_BEING_SETUP
]

class ErrorBoundary extends React.Component {
  state = {
    errorType: null
  }

  static getDerivedStateFromError({ message }) {
    if (errorTypesToBeHandled.includes(message)) {
      return {
        errorType: message
      }
    }
  }

  recoverFromError = () => {
    this.setState({
      errorType: null
    })
  }

  componentDidCatch({ message }) {
    // critcal and generic server errors like 500, 400 are handled by
    // a top level error boundary
    if (message && !errorTypesToBeHandled.includes(message)) {
      throw new Error(message)
    }
  }

  render() {
    const { errorType } = this.state
    const { children } = this.props
    if (errorType === AUTH_CONST.ORG_DOMAIN_BEING_SETUP) {
      return <OrgSetupInProgressMessage redirectTo={APP_CONSTANTS.DEFAULT_REDIRECT_PATH} />
    } else if (errorType === AUTH_CONST.AUTHENTICATIONS_METHOD_FAILED) {
      return <AuthenticationsFallback type={AUTH_CONST.AUTHENTICATIONS_METHOD_FAILED} />
    } else if (errorType === AUTH_CONST.NO_AUTHENTICATION_METHODS) {
      return <AuthenticationsFallback type={AUTH_CONST.NO_AUTHENTICATION_METHODS} />
    } else if (errorType === AUTH_CONST.NO_AUTH_OPTIONS_CONFIGURED) {
      return <AuthenticationsFallback type={AUTH_CONST.NO_AUTH_OPTIONS_CONFIGURED} />
    } else {
      return children
    }
  }
}

export default ErrorBoundary
