import React from 'react'
import Icon from '../../Components/Icon'
import Logo from '../../Components/Logo/Logo'
import { isRenderedUnderPrefix } from '../../constants'
import { useIntl } from '../../Contexts/IntlContext/IntlState'
import colors from '../../themes/freshworks/colors'
import { H3, H5, LinkText } from '../../typography'
import PropTypes from 'prop-types'
import { getLogoutUrlWithPostLogoutRedirectUrl } from '../../utils/utility'

const textAlignCenter = {
  textAlign: 'center',
  marginTop: '60px'
}

const containerStyles = {
  display: 'flex',
  justifyContent: 'center'
}

const underline = { textDecoration: 'underline' }

const urlWithPageTitleMapping = {
  'set-password': 'set_password',
  invite: 'user_activation',
  'reset-password': 'common_login.reset_password'
}

const SessionExists = ({ userEmail }) => {
  // because this page has different background even in previous version
  window.document.body.style.backgroundColor = colors.body.background.secondary

  // the better way was to pass this key from App.js down to this component
  // this involves prop drilling for 3 components
  // so did it this way using `urlWithPageTitleMapping`, this is the best way
  //in case we are using a prefix first element will be prefix, so we need to get second element
  const windowUrl = window.location.pathname.split('/')[isRenderedUnderPrefix ? 2 : 1],
    pageTitleTranslationKey = urlWithPageTitleMapping[windowUrl]
  const { toIntlString } = useIntl()
  return (
    <div style={containerStyles}>
      <div style={{ marginTop: '45px' }}>
        <div style={{ display: 'flex' }}>
          <Icon alt="Cloud" name="error_cloud" />
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Logo size="large" />
          </div>
        </div>

        <div style={textAlignCenter}>
          <H5>
            {toIntlString('session_exists.already_logged_in', {
              email: userEmail
            })}{' '}
            <LinkText>{userEmail}</LinkText>
          </H5>
          <div style={{ marginTop: '8px' }}>
            <H3>
              {toIntlString('session_exists.please')}{' '}
              <a href={getLogoutUrlWithPostLogoutRedirectUrl()} style={underline}>
                <LinkText>{toIntlString('session_exists.logout')}</LinkText>
              </a>{' '}
              {toIntlString('session_exists.from_existing_session', {
                page: toIntlString(`${pageTitleTranslationKey}.page_title`)
              })}
            </H3>
          </div>
        </div>
      </div>
    </div>
  )
}

SessionExists.propTypes = {
  userEmail: PropTypes.string
}

export default React.memo(SessionExists)
