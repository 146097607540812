import React from 'react'
import { APP_CONSTANTS } from './constants'
import { matchRoutes, useLocation, useMatch } from 'react-router-dom'
import { useIntl } from './Contexts/IntlContext/IntlState'
import { isGlobalUI } from './App'

const ROUTE_CONFIG_TO_TRANSLATION_KEY_MAP = {
  'login/reauth': 'common_login',
  '/invite/:hash': 'user_activation',
  '/forgot-password': 'common_login.forgot_password',
  '/magiclink/:hash/login': 'common_login.magiclink_login',
  '/magiclink/request': 'common_login.magiclink_login',
  '/login': 'common_login',
  '/mfa/setup': 'my_profile.multi_factor_authentication',
  '/login/update-password': 'common_login.reset_password',
  '/session-exists': 'common_login',
  '/reset-password/:hash': 'common_login.reset_password',
  '/login/mfa/auth-code': 'my_profile.multi_factor_authentication.authenticator_code_page',
  '/login/mfa/recovery-code': 'my_profile.multi_factor_authentication.recovery_code_page',
  '/set-password/:hash': 'common_login.set_password',
  '/unlock/:hash': 'unlock'
}
const setFavicon = () => {
  const head = document.head || document.getElementsByTagName('head')[0]
  const link = document.createElement('link')
  link.rel = 'shortcut icon'
  link.href = isGlobalUI
    ? 'https://dash.freshworks.com/us/assets/images/freshworks-dew.png'
    : '/api/v2/organisation/-/image?variant=ORIGINAL&entity_type=ORG_FAVICON'
  head.appendChild(link)
}

const getTitleRoute = (pathname) => {
  const routes = Object.keys(ROUTE_CONFIG_TO_TRANSLATION_KEY_MAP)
  const matchedRoute = matchRoutes(
    routes.map((route) => ({ path: route })),
    pathname
  )
  return ROUTE_CONFIG_TO_TRANSLATION_KEY_MAP[matchedRoute && matchedRoute[0].route.path]
}

const setDocumentTitle = (pathname, toIntlString) => {
  const route = getTitleRoute(pathname)
  if (!isGlobalUI) {
    document.title =
      APP_CONSTANTS.FRESHWORKS + (route ? ` - ${toIntlString(`${route}.page_title`)}` : '')
  } else {
    document.title = 'Login | Freshworks'
  }
}

const addMetaTags = () => {
  const meta = document.createElement('meta')
  meta.name = isGlobalUI ? 'description' : 'robots'
  meta.content = isGlobalUI
    ? 'Log in to your Freshworks customer account. Securely sign into Freshdesk, Freshservice, Freshsales, and other Freshworks products. No login? Sign up for a free trial.'
    : 'noindex'
  document.getElementsByTagName('head')[0].appendChild(meta)
}

const setCanonicalTag = () => {
  if (isGlobalUI) {
    const canonical = document.createElement('link')
    canonical.rel = 'canonical'
    canonical.href = 'https://login.freshworks.com/email-login/'
    document.getElementsByTagName('head')[0].appendChild(canonical)
  }
}

const HeadManager = () => {
  const { pathname } = useLocation()
  const { toIntlString } = useIntl()
  React.useEffect(() => {
    setFavicon()
    setDocumentTitle(pathname, toIntlString)
    addMetaTags()
    setCanonicalTag()
  }, [window.location.pathname])

  return null
}

export default HeadManager
