import React, { useContext } from 'react'

import useFetch from '../Components/useFetch/useFetch'
import { APP_CONSTANTS } from '../constants'
import AppDataProviderContext from '../Contexts/AppDataProvider/AppDataProviderContext'
import { getOrgDomainFromContext } from './utility'

class OrgUtils {
  IsDomainAvailable = ({ children, onSuccess, onError }) => {
    const orgDomain = getOrgDomainFromContext(useContext(AppDataProviderContext))

    useFetch({
      requestName: 'checkIfOrgDomainExists',
      pathFragments: [orgDomain],
      onSuccessHandler: onSuccess,
      onErrorHandler: (error) => {
        if (!!onError && typeof onError === 'function') {
          return onError(error)
        }
        window.location.assign(APP_CONSTANTS.FRESHWORKS_HOME)
      }
    })
    return children
  }
}

export default new OrgUtils()

export const orgConfigInterceptor = (orgConfig) => {
  /**
   * @Desc Until backend is no sending organisationDomain, default behaviour is to assume this is orgUrl
   */
  orgConfig.loadedInOrgDomain = orgConfig.organisationDomain
    ? orgConfig.organisationDomain === window.location.host
    : true
  return orgConfig
}
