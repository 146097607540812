import { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import './App.css'
import { NotFoundContent } from './Components/Errors/PageNotFound'
import TopErrorBoundary from './Components/TopErrorBoundary/TopErrorBoundary'
import ShouldRenderTPS from './Components/TPSLoader'
import UserSession from './Components/UserSession/UserSession'
import Unlock from './Pages/Unlock/Unlock'
import retry from './utils/promiseRetry'
import HeadManager from './HeadManager'
import AppDataProviderStateWrapper from './Contexts/AppDataProvider/AppDataProviderState'
import AppWrapper from './AppWrapper'

const ActivateUser = lazy(() => retry(() => import('./Pages/ActivateUser')))
const ConfigureMFA = lazy(() => retry(() => import('./Pages/ConfigureMFA/ConfigureMFA')))
const ForgotPassword = lazy(() => retry(() => import('./Pages/ForgotPassword/ForgotPassword')))
const Login = lazy(() => retry(() => import('./Pages/Login')))
const UpdatePassword = lazy(() =>
  retry(() => import('./Pages/Login/UpdatePassword/UpdatePassword'))
)
const MagicLinkLogin = lazy(() => retry(() => import('./Pages/MagicLinkLogin/MagicLinkLogin')))
const SessionExists = lazy(() => retry(() => import('./Pages/MagicLinkLogin/SessionExists')))
const MFAValidation = lazy(() => retry(() => import('./Pages/MFAValidation/MFAValidation')))
const RequestMagicLink = lazy(() => retry(() => import('./Pages/RequestMagicLink')))
const ResetPassword = lazy(() => retry(() => import('./Pages/ResetPassword')))
const SetPassword = lazy(() => retry(() => import('./Pages/SetPassword/SetPassword')))
const EmailLogin = lazy(() => retry(() => import('./Pages/EmailLogin')))
const DomainLogin = lazy(() => retry(() => import('./Pages/DomainLogin')))
const ForgotDomain = lazy(() => retry(() => import('./Pages/ForgotDomain')))

const GLOBAL_UI_ROUTES = ['/email-login', '/domain-login', '/forgot-domain']

export const isGlobalUI = GLOBAL_UI_ROUTES.some((route) => window.location.pathname.includes(route))

function App() {
  return (
    <AppWrapper>
      <HeadManager />
      <AppDataProviderStateWrapper renderWithoutContext={isGlobalUI}>
        <TopErrorBoundary>
          <ShouldRenderTPS>
            <Routes>
              <Route
                path="/login"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Login />
                  </Suspense>
                }
              />

              <Route
                path="/login/reauth"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Login />
                  </Suspense>
                }
              />

              <Route
                path="/invite/:hash"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <UserSession.DoesSessionExists>
                      <ActivateUser />
                    </UserSession.DoesSessionExists>
                  </Suspense>
                }
              />
              <Route
                path="/magiclink/:hash/login"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <MagicLinkLogin />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/magiclink/request"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <RequestMagicLink />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/forgot-password"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <ForgotPassword />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/reset-password/:hash"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <UserSession.DoesSessionExists>
                      <ResetPassword />
                    </UserSession.DoesSessionExists>
                  </Suspense>
                }
              />
              <Route
                exact
                path="/set-password/:hash"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <UserSession.DoesSessionExists>
                      <SetPassword />
                    </UserSession.DoesSessionExists>
                  </Suspense>
                }
              />
              <Route
                path="/login/update-password"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <UpdatePassword />
                  </Suspense>
                }
              />
              <Route
                path="/mfa/setup"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <ConfigureMFA />
                  </Suspense>
                }
              />
              <Route
                path="/login/mfa/*"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <MFAValidation />
                  </Suspense>
                }
              />
              <Route
                path="/session-exists"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <SessionExists />
                  </Suspense>
                }
              />

              <Route path="/unlock/:hash" element={<Unlock />} />

              {/* Routes For Global Login UI Starts Here */}
              <Route
                path="/email-login"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <EmailLogin />
                  </Suspense>
                }
              />

              <Route
                path="/domain-login"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <DomainLogin />
                  </Suspense>
                }
              />

              <Route
                path="/forgot-domain"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <ForgotDomain />
                  </Suspense>
                }
              />

              {/* Routes For Global Login UI Ends Here */}

              <Route
                path="*"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <NotFoundContent />
                  </Suspense>
                }
              />
            </Routes>
          </ShouldRenderTPS>
        </TopErrorBoundary>
      </AppDataProviderStateWrapper>
    </AppWrapper>
  )
}

export default App
